import React, { useEffect } from "react";
import "./preloader.css";

function Preloader() {

  useEffect(() => {
    let preload = document.querySelector(".preloader");
    setTimeout(() => {
      preload.classList.add("slide-out");

      setTimeout(() => {
        preload.style.display = "none";
      }, 500);
    }, 250);
  });

  return (
    <div className="preloader" id="preloader">
      <div className="spinner_wrap">
        <div className="spinner" />
      </div>
    </div>
  );
}

export default Preloader;
