import React, { useState, useEffect, useRef } from "react";
import "./topbar.css";
import useLoginStatus from "../../hooks/loginCheck";

const Topbar = () => {
  const ref = useRef(null);
  const [firstName, setFirstName] = useState(
    localStorage.getItem("firstname") || ""
  );
  const [lastName, setLastName] = useState(
    localStorage.getItem("lastname") || ""
  );
  const [initals, setInitials] = useState(
    localStorage.getItem("initials") || ""
  );
  const [nextAppointmentStart, setNextAppointmentStart] = useState(
    localStorage.getItem("next_appointment_start") || ""
  );
  const [nextAppointmentEnd, setNextAppointmentEnd] = useState(
    localStorage.getItem("next_appointment_end") || ""
  );
  
  const isLoggedIn = useLoginStatus();

  const formatTimeslot = (nextAppointmentStart, nextAppointmentEnd) => {
    if (nextAppointmentStart && nextAppointmentEnd) {
      const startDate = new Date(nextAppointmentStart);
      /* const endDate = new Date(nextAppointmentEnd); */
      const dayName = startDate.toLocaleDateString("de-DE", { weekday: 'long' });

      const formattedStartDate = startDate.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      });
      const formattedStartTime = startDate.toLocaleTimeString("de-DE", {
        hour: "2-digit",
        minute: "2-digit",
      });
      /* const formattedEndTime = endDate.toLocaleTimeString("de-DE", {
        hour: "2-digit",
        minute: "2-digit",
      }); */

      /* return `${dayName}, ${formattedStartDate} - ${formattedStartTime} - ${formattedEndTime}`; */
      return `${dayName}, ${formattedStartDate} - ${formattedStartTime}`;
    }
    return null;
  };

  const nameInitials = (firstName, lastName) => {
    const firstNameLetter = firstName;
    const lastNameLetter = lastName;

    if (firstName && lastName) {
      const intialsT =
        firstNameLetter.slice(0, 1).toUpperCase() +
        lastNameLetter.slice(0, 1).toUpperCase();
      setInitials(intialsT);
      localStorage.setItem("initials", intialsT);
      return `${intialsT}`;
    }
    return null;
  };

  useEffect(() => {
    nameInitials(firstName, lastName);
    window.addEventListener("storage", (e) => {
      setFirstName(localStorage.getItem("firstname"));
      setLastName(localStorage.getItem("lastname"));
      setNextAppointmentStart(localStorage.getItem("next_appointment_start"))
      setNextAppointmentEnd(localStorage.getItem("next_appointment_end"))
      nameInitials(firstName, lastName);
    });
    return () => {
      window.removeEventListener("storage", nameInitials);
    };
  }, [firstName, lastName]);

  let userSettings = () => {
    localStorage.removeItem("modal_status");
    window.dispatchEvent(new Event("storagechange"));
  };

  return (
    <>
      {isLoggedIn && (
        <div className="topbar" id="topbar" ref={ref}>
          <div className="topbar-container">
            <div
              style={{ display: initals ? "block" : "none" }}
              className="user-icon"
            >
              {initals}
            </div>
            <p
              className="topbar-user-greeting"
              style={{
                marginRight: "auto",
                display: firstName ? "block" : "none",
              }}
            >
              Hey, {firstName}!
            </p>
            <p
              className="topbar-user-appointment"
              style={{
                marginRight: "auto",
                display:
                  nextAppointmentStart && nextAppointmentEnd ? "block" : "none",
              }}
            >
              <i
                className="fas fa-calendar"
                style={{ marginRight: ".5rem" }}
              ></i>
              <span>Dein nächster Termin:</span>{" "}
              <strong>
                {formatTimeslot(nextAppointmentStart, nextAppointmentEnd)}
              </strong>
            </p>
            <button className="user-settings" onClick={userSettings}>
              <i className="fas fa-sliders" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Topbar;
