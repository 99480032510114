import AccordionItem from "./AccordionItem";
import { useState, useRef, useEffect } from "react";
import { useInView } from "framer-motion";
import "./accordion.css";

const Accordion = ({ accordion, fromJson }) => {
  const [openKey, setOpenKey] = useState();
  const accordionRef = useRef();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const handleOutsideClick = (e) => {
    if (accordionRef.current && !accordionRef.current.contains(e.target)) {
      handleToggle();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  const handleToggle = (key) => {
    setOpenKey(openKey !== key ? key : null);
  };
  if (fromJson)
    return (
      <div
        className={["accordion-container"].join(" ")}
        ref={ref}
        style={{
          transform: isInView ? "none" : "translateY(100px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        <div className="accordion-items-container" ref={accordionRef}>
          {accordion.map((accordion, index) => (
            <AccordionItem
              key={index}
              accordion={accordion}
              toggle={handleToggle}
              open={openKey === accordion.title}
            />
          ))}
        </div>
      </div>
    );
  if (!fromJson)
    return (
      <div
        style={{
          transform: isInView ? "none" : "translateY(100px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
        className={["accordion-container"].join(" ")}
        ref={ref}
      >
        <div className="accordion-items-container" ref={accordionRef}>
          <AccordionItem
            fromJson={fromJson}
            key={accordion.index}
            accordion={accordion}
            toggle={handleToggle}
            open={openKey === accordion.title}
          />
        </div>
      </div>
    );
};

export default Accordion;
