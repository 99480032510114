import { React } from "react";
import "./test.css";
import {
  Divider,
  EndlessSlider,
  Grid,
  Modal,
  Parallax,
  ZoomIn,
  FadeText,
} from "../../components";
import {
  parallaxData,
  endlessSliderData,
  zoomInData,
} from "../../data/DataDefault";

import { HomepageModal } from "../../data/DataHomepage";


function Test() {
  const parallax = parallaxData;
  const endlessSlider = endlessSliderData;
  const modal = HomepageModal;
  const zoomin = zoomInData;

  return (
    <div className="content">
      <Grid
        grid={{
          className: "page",
          pageName: "test-page",
          isFull: true,
        }}
      >
        <FadeText text="Echtes Handwerk. Leidenschaft & Qualität" />
        <ZoomIn zoomin={zoomin} />
        <Parallax parallax={parallax} />
        <FadeText text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Id modi sunt, tenetur quibusdam sint autem debitis consectetur ducimus voluptatem " />
        <Divider
          divider={{
            dividerHeight: "200px",
            dividerHeightMobile: "100px",
            id: 1,
          }}
        />
        <EndlessSlider
          sliderDuration={30}
          endlessSlider={endlessSlider.filter(
            (endlessSlider) => endlessSlider.section === 1
          )}
        />
        <EndlessSlider
          sliderDuration={35}
          endlessSlider={endlessSlider.filter(
            (endlessSlider) => endlessSlider.section === 2
          )}
        />
        <ZoomIn zoomin={zoomin} />
        <Divider
          divider={{
            dividerHeight: "200px",
            dividerHeightMobile: "100px",
            id: 1,
          }}
        />
        <Modal modal={modal.filter((modal) => modal.section === 1)} />
      </Grid>
    </div>
  );
}

export default Test;
