import { React, useRef } from "react";
import { useInView } from "framer-motion";
import "./box.css";
import { NavLink } from "react-router-dom";

function Box({ box, fromJson }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  if (fromJson) {
    return (
      <>
        {box.map((box) => (
              <div
              key={box.id}
              style={{
                transform: isInView ? "none" : "translateY(100px)",
                opacity: isInView ? 1 : 0,
                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
              }}
                className={[
                  box.className,
                ].join(" ")}
                ref={ref}
              >
                <div className="box-img__container">
                  {box.imgpath && (
                    <img
                      className={box.imgClassName}
                      onError={(e) => (e.target.style.display = "none")}
                      src={box.imgpath}
                      alt=""
                    />
                  )}
                </div>
                {box.title && (
                  <div className="box-info__container">
                  {box.title && (
                    <h4 className={box.headlineClassname}>{box.title}</h4>
                  )}
                  {box.description && (
                    <p
                      className={box.textClassname}
                      dangerouslySetInnerHTML={{ __html: box.description }}
                    ></p>
                  )}
                  {box.secondDescription && (
                    <p className={box.textClassname}>{box.secondDescription}</p>
                  )}
                  {box.thirdDescription && (
                    <p className={box.textClassname}>{box.thirdDescription}</p>
                  )}
                  {box.fourthDescription && (
                    <p className={box.textClassname}>{box.fourthDescription}</p>
                  )}
                  {box.fifthDescription && (
                    <p className={box.textClassname}>{box.fifthDescription}</p>
                  )}
                  {box.linkName && (
                    <a href={box.linkTarget} className={box.textClassname}>
                      {box.linkNameIcon && (
                        <span>
                          <i className={box.linkNameIcon}></i>
                        </span>
                      )}{" "}
                      {box.linkName}
                    </a>
                  )}
                  {box.secondLinkName && (
                    <a
                      href={box.secondLinkTarget}
                      className={box.textClassname}
                    >
                      {box.secondLinkNameIcon && (
                        <span>
                          <i className={box.secondLinkNameIcon}></i>
                        </span>
                      )}{" "}
                      {box.secondLinkName}
                    </a>
                  )}
                  {box.thirdLinkName && (
                    <a href={box.thirdLinkTarget} className={box.textClassname}>
                      {box.thirdLinkNameIcon && (
                        <span>
                          <i className={box.thirdLinkNameIcon}></i>
                        </span>
                      )}{" "}
                      {box.thirdLinkName}
                    </a>
                  )}
                  {box.buttonClassname && (
                    <NavLink to={box.buttonLink} type="button" className={box.buttonClassname}>
                      {box.buttonText}
                    </NavLink>
                  )}
                  {box.boxIcon && (
                    <div className="box-icon-container text-bright">
                      <i className={box.boxIcon} />
                    </div>
                  )}
                </div>
                )}
              </div>
        ))}
      </>
    );
  }
  if (!fromJson) {
    return (
      <>
            <div
            style={{
              transform: isInView ? "none" : "translateY(100px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
              className={[
                box.className,
              ].join(" ")}
              ref={ref}
            >
              <div className="box-img__container">
                {box.imgpath && (
                  <img
                    className={box.imgClassName}
                    onError={(e) => (e.target.style.display = "none")}
                    src={box.imgpath}
                    alt=""
                  />
                )}
              </div>
              {box.title && (
                <div className="box-info__container">
                {box.headlineClassname && (
                  <h4 className={box.headlineClassname}>{box.title}</h4>
                )}
                {box.description && (
                  <p
                    className={box.textClassname}
                    dangerouslySetInnerHTML={{ __html: box.description }}
                  ></p>
                )}
                {box.secondDescription && (
                  <p className={box.textClassname}>{box.secondDescription}</p>
                )}
                {box.thirdDescription && (
                  <p className={box.textClassname}>{box.thirdDescription}</p>
                )}
                {box.fourthDescription && (
                  <p className={box.textClassname}>{box.fourthDescription}</p>
                )}
                {box.fifthDescription && (
                  <p className={box.textClassname}>{box.fifthDescription}</p>
                )}
                {box.linkName && (
                  <a href={box.linkTarget} className={box.textClassname}>
                    {box.linkNameIcon && (
                      <span>
                        <i className={box.linkNameIcon}></i>
                      </span>
                    )}{" "}
                    {box.linkName}
                  </a>
                )}
                {box.secondLinkName && (
                  <a href={box.secondLinkTarget} className={box.textClassname}>
                    {box.secondLinkNameIcon && (
                      <span>
                        <i className={box.secondLinkNameIcon}></i>
                      </span>
                    )}{" "}
                    {box.secondLinkName}
                  </a>
                )}
                {box.thirdLinkName && (
                  <a href={box.thirdLinkTarget} className={box.textClassname}>
                    {box.thirdLinkNameIcon && (
                      <span>
                        <i className={box.thirdLinkNameIcon}></i>
                      </span>
                    )}{" "}
                    {box.thirdLinkName}
                  </a>
                )}
                {box.buttonClassname && (
                  <button type="button" className={box.buttonClassname}>
                    {box.buttonText}
                  </button>
                )}
              </div>
              )}
            </div>
      </>
    );
  }
}

export default Box;
