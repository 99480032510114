import { React } from "react";
import "./services.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Highlights, Grid, Preloader, Hero, Modal, Cta, Headline, Divider, List } from "../../components";
import { CompanyName } from "../../data";
import {
  ServicespageHighlights,
  ServicespageHero,
  ServicespageMeta,
  ServicespageHeadline,
  ServicespageCta,
} from "../../data/DataServicesPage";
import { HomepageModal } from "../../data/DataHomepage";
import useLoginStatus from "../../hooks/loginCheck";

const CompanyNameTitle = CompanyName;

function Services() {
  const hero = ServicespageHero;
  const highlights = ServicespageHighlights;
  const meta = ServicespageMeta;
  const modal = HomepageModal;
  const cta = ServicespageCta;
  const headline = ServicespageHeadline;
  const isLoggedIn = useLoginStatus();

  return (
    <div className="content">
      {isLoggedIn && (
        <HelmetProvider>
          <Grid
            grid={{
              className: "page",
              pageName: "services-page",
              isFull: true,
            }}
          >
            <Helmet>
              <html lang="de" />
              <title>{CompanyNameTitle} - Leistungen</title>
              <meta name="description" content={meta} />
            </Helmet>
            <Hero hero={hero} />
          </Grid>
          <Grid
            grid={{
              className: "page",
              pageName: "services-page",
              isFull: false,
            }}
          >
            <Divider divider={{ dividerHeight: "50px", dividerHeightMobile: "25px", id: 1 }} />
            <Headline
              headline={headline.filter((headline) => headline.section === 1)}
            />
            <Divider divider={{ dividerHeight: "100px", dividerHeightMobile: "50px", id: 1 }} />
            <Highlights highlights={highlights} isBookable={true}/>
            <Divider divider={{ dividerHeight: "200px", dividerHeightMobile: "100px", id: 1 }} />
            <Headline
              headline={headline.filter((headline) => headline.section === 2)}
            />
             <Divider divider={{ dividerHeight: "100px", dividerHeightMobile: "50px", id: 1 }} />
            <List isBookable={true} />
            <Divider divider={{ dividerHeight: "200px", dividerHeightMobile: "100px", id: 1 }} />
            <Cta cta={cta.filter((cta) => cta.section === 2)} />
            <Divider divider={{ dividerHeight: "200px", dividerHeightMobile: "100px", id: 1 }} />
            <Modal modal={modal.filter((modal) => modal.section === 1)} />
          </Grid>
          <Preloader />
        </HelmetProvider>
      )}
    </div>
  );
}

export default Services;
