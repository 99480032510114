import React, { useRef } from "react";
import { useInView } from "framer-motion";
import "./hero.css";

function Hero(props) {
  const hero = props.hero;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <>
      {hero.map((hero) => (
        <div
        ref={ref}
          style={{
            transform: isInView ? "none" : "translateY(100px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            ...(hero.backgroundImage
              ? {
                  background: `rgba(0, 0, 0, .85) url(${hero.backgroundImage})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }
              : {}),
          }}
          id={hero.cssid}
          className={[hero.className].join(" ")}
          key={hero.id}
        >
          <div className={hero.containerClassName}>
            {hero.title && <h1>{hero.title}</h1>}
            {hero.description && (
              <p dangerouslySetInnerHTML={{ __html: hero.description }}></p>
            )}
            {hero.buttonText && (
              <a className={hero.buttonClass} href={hero.buttonLink}>
                <span className="hero-button-container">
                  {hero.icon && (
                    <span className="hero-button-icon">
                      <i className={hero.icon}></i>
                    </span>
                  )}
                  <span className="hero-button-text">{hero.buttonText}</span>
                </span>
              </a>
            )}
          </div>
        </div>
      ))}
    </>
  );
}

export default Hero;
