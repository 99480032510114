import { useState, useEffect } from 'react';
  
  export default function useLoginStatus() {
    const [isLoggedIn, setLogin] = useState(false);
  
    useEffect(() => {
        window.addEventListener("storage", (e) => {
          let getLoginStatus = localStorage.getItem("isLoggedIn");
          getLoginStatus ? setLogin(true) : setLogin(false);
        });
        let getLoginStatus = localStorage.getItem("isLoggedIn");
        getLoginStatus ? setLogin(true) : setLogin(false);
      }, []);
    
  
    return isLoggedIn;
  }