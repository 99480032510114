import { React } from "react";
import "./projects.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  Box,
  Grid,
  Preloader,
  Hero,
  Modal,
  Slider,
  Headline,
  Divider,
  Gallery,
} from "../../components";
import { CompanyName } from "../../data";
import {
  ProjectspageBox,
  ProjectspageHero,
  ProjectspageMeta,
  ProjectspageSlides,
  ProjectspageHeadline,
} from "../../data/DataProjectsPage";
import { GalleryItems } from "../../data/DataDefault";
import { HomepageModal } from "../../data/DataHomepage";
import useLoginStatus from "../../hooks/loginCheck";

const CompanyNameTitle = CompanyName;

function Legal() {
  const box = ProjectspageBox;
  const hero = ProjectspageHero;
  const meta = ProjectspageMeta;
  const modal = HomepageModal;
  const slides = ProjectspageSlides;
  const headline = ProjectspageHeadline;
  const gallery = GalleryItems;
  const isLoggedIn = useLoginStatus();

  return (
    <div className="content">
      {isLoggedIn && (
        <HelmetProvider>
          <Grid
            grid={{
              className: "page",
              pageName: "projects-page",
              isFull: true,
            }}
          >
            <Helmet>
              <html lang="de" />
              <title>{CompanyNameTitle} - Projekte</title>
              <meta name="description" content={meta} />
            </Helmet>
            <Hero hero={hero} />
          </Grid>
          <Grid
            grid={{
              className: "page",
              pageName: "projects-page",
              isFull: false,
            }}
          >
            <Divider
              divider={{
                dividerHeight: "50px",
                dividerHeightMobile: "25px",
                id: 1,
              }}
            />
            <Headline
              headline={headline.filter((headline) => headline.section === 1)}
            />
            <Divider
              divider={{
                dividerHeight: "100px",
                dividerHeightMobile: "50px",
                id: 1,
              }}
            />
            <Slider slides={slides} size={"one-third"} />
            <Box fromJson={true} box={box.filter((box) => box.section === 1)} />
            <Divider
              divider={{
                dividerHeight: "100px",
                dividerHeightMobile: "50px",
                id: 1,
              }}
            />
            <Slider slides={slides} size={"one-third"} />
            <Box fromJson={true} box={box.filter((box) => box.section === 2)} />
            <Divider
              divider={{
                dividerHeight: "100px",
                dividerHeightMobile: "50px",
                id: 1,
              }}
            />
            <Slider slides={slides} size={"one-third"} />
            <Box fromJson={true} box={box.filter((box) => box.section === 3)} />
            <Divider
              divider={{
                dividerHeight: "100px",
                dividerHeightMobile: "50px",
                id: 1,
              }}
            />
            <Headline
              headline={headline.filter((headline) => headline.section === 2)}
            />
            <Divider
              divider={{
                dividerHeight: "100px",
                dividerHeightMobile: "50px",
                id: 1,
              }}
            />
            <Gallery gallery={gallery} />
            <Modal modal={modal.filter((modal) => modal.section === 1)} />
          </Grid>
          <Preloader />
        </HelmetProvider>
      )}
    </div>
  );
}

export default Legal;
