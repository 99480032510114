import React from 'react';
import './index.css';
import {
  About,
  Home,
  Contact,
  Legal,
  Customization,
  Projects,
  Services,
  Test,
  NotFound,
  Termin
} from './pages';
import {
  Footer,
  NavigationBar,
  Topbar,
  ToTopButton,
  Fullscreen
} from './components';
import reportWebVitals from './reportWebVitals';
import {
  createRoot
} from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {
  BrowserRouter as Router, Routes, Route, Navigate
} from "react-router-dom";
import 'animate.css';
import ScrollToTop from './hooks/ScrollToTop';
import LocalStorageCheck from './helpers/LocalStorageCheck';


const container = document.getElementById('root');
const root = createRoot(container);



let primaryColor = localStorage.getItem('primarycolor');
let secondaryColor = localStorage.getItem('secondarycolor');
let tertiaryColor = localStorage.getItem('tertiarycolor');
let backgroundColor = localStorage.getItem('backgroundcolor');
let activeColor = localStorage.getItem('activecolor');


if (primaryColor === ('' || null)) {
  document.documentElement.style.setProperty('--primary-color', "#1e1e1e");
  localStorage.setItem('primarycolor', "#1e1e1e")
}
else {
  document.documentElement.style.setProperty('--primary-color', primaryColor);
}
if (secondaryColor === ('' || null)) {
  document.documentElement.style.setProperty('--secondary-color', "#eaeaea");
  localStorage.setItem('secondarycolor', "#eaeaea")
}
else {
  document.documentElement.style.setProperty('--secondary-color', secondaryColor);
}
if (tertiaryColor === ('' || null)) {
  document.documentElement.style.setProperty('--tertiary-color', "#0d0d0d");
  localStorage.setItem('tertiarycolor', "#0d0d0d")
}
else {
  document.documentElement.style.setProperty('--tertiary-color', tertiaryColor);
}
if (backgroundColor === ('' || null)) {
  document.documentElement.style.setProperty('--background-color', "#f0f0f0");
  localStorage.setItem('backgroundcolor', "#f0f0f0")
}
else {
  document.documentElement.style.setProperty('--background-color', backgroundColor);
}
if (activeColor === ('' || null)) {
  document.documentElement.style.setProperty('--active-color', "#e77100");
  localStorage.setItem('activecolor', "#e77100")
}
else {
  document.documentElement.style.setProperty('--activecolor-color', backgroundColor);
}


root.render(<React.StrictMode>
  <Router>
    <ScrollToTop />
    <LocalStorageCheck />
    <Fullscreen isLoggedIn={true} />
    <header id='header'>
      <Topbar />
      <NavigationBar />
    </header>
    
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/legal" element={<Legal />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/services" element={<Services />} />
      <Route path="/termin" element={<Termin />} />
      <Route path="/customization" element={<Customization />} />
      <Route path="/test" element={<Test />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={ <Navigate to="/404" replace />} />
    </Routes>
    <ToTopButton />
    <Footer />
  </Router>

</React.StrictMode>);

reportWebVitals();

serviceWorkerRegistration.register();

