import React from 'react';
import "./endlessslider.css";

const EndlessSlider = (props) => {
  const endlessSliderData = props.endlessSlider;
  const duration = props.sliderDuration;

  // Duplicate items once
  const items = [...endlessSliderData, ...endlessSliderData, ...endlessSliderData, ...endlessSliderData];

  return (
    <div className="endless-slider-wrapper">
      <div className="endless-slider-container" style={{ animation: `endless-slide ${duration}s linear infinite` }}>
        {items.map((item, index) => (
          <div
            key={index}
            className="endless-slider-item"
            style={{
              backgroundImage: `url(${item.url})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              zIndex: "0",
              marginTop: "0rem",
              borderRadius: "calc(var(--button-border-radius) / 2)",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default EndlessSlider;
