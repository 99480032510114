import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { TbRazor } from "react-icons/tb";
import { RiScissorsLine } from "react-icons/ri";
import { FaMask, FaStar } from "react-icons/fa";
import "./list.css";
import { listItems } from "../../data/DataDefault";

function List(props) {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const navigate = useNavigate();
  const items = listItems;
  const isBookingForm = props.isBookingForm;
  const isBookable = props.isBookable;
  const onSelectItems = props.onSelectItems
  const serviceId = props.serviceId


  useEffect(() => {
    if (serviceId) {
      const filteredItem = items.find(item => item.serviceId === serviceId && item.bookable);
      if (filteredItem) {
        onSelectItems([filteredItem]);
      } else {
        alert("The service selected is not bookable or does not exist.");
      }
    }
  });

  const toggleCategory = (category) => {
    setSelectedCategories((prevState) =>
      prevState.includes(category)
        ? prevState.filter((cat) => cat !== category)
        : [...prevState, category]
    );
  };

  const toggleAllCategories = () => {
    if (
      selectedCategories.length === categories.length ||
      selectedCategories.length === 0
    ) {
      setSelectedCategories([]);
    } else {
      setSelectedCategories(categories);
    }
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const categories = [...new Set(items.map((item) => item.category))];

  const filteredItems = items
    .filter((item) => {
      const matchesCategory =
        selectedCategories.length === 0 ||
        selectedCategories.includes(item.category);
      const matchesSearch =
        searchText.length < 3 ||
        item.name.toLowerCase().includes(searchText.toLowerCase());
      return matchesCategory && matchesSearch;
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const getCategoryIcon = (category) => {
    switch (category) {
      case "VIP":
        return <FaStar />;
      case "Haare":
        return <RiScissorsLine />;
      case "Bart":
        return <TbRazor />;
      case "Kosmetik":
        return <FaMask />;
      default:
        return null;
    }
  };

  const listVariants = {
    visible: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: { duration: 0.5, delay: i * 0.075 },
    }),
    exit: (i) => ({
      opacity: 0,
      x: -20,
      transition: { duration: 0.3, delay: i * 0.075 },
    }),
  };

  const handleItemClick = (item) => {
    if (isBookable && item.bookable) {
      navigate(`/termin?serviceId=${item.serviceId}`);
    } else {
      setSelectedItems((prevSelected) =>
        prevSelected.includes(item)
          ? prevSelected.filter((i) => i !== item)
          : [...prevSelected, item]
      );
    }
  };



  return (
    <div className="list-wrapper">
      {isBookingForm && <h3>Leistung auswählen</h3>}
      <div className="list-container">
        <input
          className="list-search-input"
          type="text"
          placeholder="Leistung suchen"
          value={searchText}
          onChange={handleSearchChange}
        />
        <div className="filter-buttons-wrapper">
          <div className="filter-buttons-container">
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => toggleCategory(category)}
                className={
                  selectedCategories.length === 0 ||
                  selectedCategories.includes(category)
                    ? "btn-dark"
                    : "btn-outline"
                }
              >
                {category}
              </button>
            ))}

            <button
              onClick={toggleAllCategories}
              className={
                selectedCategories.length === categories.length ||
                selectedCategories.length === 0
                  ? "btn-dark"
                  : "btn-outline"
              }
            >
              {selectedCategories.length === categories.length ||
              selectedCategories.length === 0
                ? "Alle"
                : "Zeige Alle"}
            </button>
          </div>
        </div>
        {filteredItems.length === 0 ? (
          <p>
            <i className="fas fa-exclamation-triangle" />
            Keine Ergebnisse gefunden.
          </p>
        ) : (
          <motion.ul
            className="list-items"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={listVariants}
          >
            <AnimatePresence>
              {filteredItems.map((item, index) => (
                <motion.li
                  key={item.id}
                  custom={index}
                  variants={itemVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  onClick={() => handleItemClick(item)}
                  className={`list-item ${!item.bookable ? "non-bookable" : ""} ${selectedItems.includes(item) ? "selected" : ""}`}
                >
                  <span className="list-item-details">
                    <span className="list-item-category">
                      {getCategoryIcon(item.category)}
                    </span>
                    <span className="list-item-title">{item.name}</span>
                  </span>
                  <span className="list-item-price">{item.price}</span>
                </motion.li>
              ))}
            </AnimatePresence>
          </motion.ul>
        )}
        {isBookingForm && (
          <button
            onClick={() => props.onSelectItems(selectedItems)}
            className="btn-outline"
            disabled={selectedItems.length === 0}
          >
            Auswahl bestätigen
          </button>
        )}
      </div>
    </div>
  );
}

export default List;