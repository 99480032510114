import React, { useState, useEffect, useRef } from "react";
import "./modal.css";
import { Userform, Toast } from "../../components";
import { DefaultUserform } from "../../data/DataDefault";
import { Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

function Modal(props) {
  const modal = props.modal;
  const userform = DefaultUserform;
  const [toggleModal, setToggleModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const modalRef = useRef();
  const currentDate = new Date().toISOString();

  function handleClick() {
    setToggleModal((current) => !current);
    localStorage.setItem("modal_status", "true");
    localStorage.setItem("current_date", currentDate);

    if (localStorage.getItem("toast_shown") !== "true") {
      setShowToast(true);
      localStorage.setItem("toast_shown", "true");
      localStorage.setItem("current_date", currentDate);
    }
  }

  const currentLocation = useLocation();
  setTimeout(() => {
    const checkModalLink = document.getElementById("modal-link");
    if (checkModalLink !== null && currentLocation.pathname === "/contact") {
      checkModalLink.style.display = "none";
    }
  }, 1);

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setToggleModal((current) => !current);
      localStorage.setItem("modal_status", "true");
      localStorage.setItem("current_date", currentDate);

      if (localStorage.getItem("toast_shown") !== "true") {
        setShowToast(true);
        localStorage.setItem("toast_shown", "true");
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  useEffect(() => {
    let modalStatus = localStorage.getItem("modal_status", "");
    setTimeout(() => {
      if (modalStatus === "null") {
        setToggleModal(false);
      }
      if (modalStatus === "true") {
        setToggleModal(false);
      }
      if (modalStatus !== "true") {
        setToggleModal(true);
      }
    }, 50);

    window.addEventListener("storagechange", (e) => {
      let modalStatus = localStorage.getItem("modal_status", "");
      if (modalStatus === "") {
        setToggleModal(false);
      }
      if (modalStatus === "true") {
        setToggleModal(false);
      }
      if (modalStatus !== "true") {
        setToggleModal(true);
      }
    });
  }, []);

  return (
    <>
      <AnimatePresence>
        {toggleModal &&
          modal.map((modal) => (
            <motion.div
              key={modal.id}
              initial={{ opacity: 0, y: -150, scale: 1, borderRadius: 0 }}
              animate={{ opacity: 1, y: 0, scale: 1, borderRadius: 0 }}
              exit={{ opacity: 0, y: 0, scale: 0.25, borderRadius: 'var(--button-border-radius)' }}
              transition={{ duration: 0.25 }}
              style={{ display: toggleModal ? "flex" : "none" }}
              id="modal"
              className={modal.className}
            >
              <div className={modal.containerClassName} ref={modalRef}>
                {modal.icon && (
                  <div>
                    <i className={modal.icon}></i>
                  </div>
                )}
                {modal.title && <h3>{modal.title}</h3>}
                {modal.text && <p>{modal.text}</p>}
                {modal.textTwo && <p>{modal.textTwo}</p>}
                {modal.ctaLink && (
                  <Link
                    id="modal-link"
                    className="btn-outline"
                    to={{ pathname: modal.ctaLink }}
                    onClick={handleClick}
                  >
                    <span>
                      <i className={modal.ctaIcon}></i>
                    </span>
                    <span>{modal.ctaText}</span>
                  </Link>
                )}
                {modal.button && (
                  <Userform
                    darkmodeActive={true}
                    editable={false}
                    customizable={false}
                    userform={userform}
                    disableAnimation={true}
                  />
                )}
                <Button className="btn-dark" onClick={handleClick}>
                  Schließen
                </Button>
              </div>
            </motion.div>
          ))}
      </AnimatePresence>
      {showToast && <Toast message="Termin buchen!" duration={3000} />}
    </>
  );
}

export default Modal;
