import React from "react";
import { AiFillCheckCircle } from "react-icons/ai";

const Saved = () => {
  
  return (
    <p className="saved"><span>Anpassungen gespeichert</span><AiFillCheckCircle /></p>
  );
};

export default Saved;
