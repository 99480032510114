export const ServicespageBox = [
      
]

export const ServicespageHero = [

    { 
      title: 'Services & Leistungen', 
      description: 'Willkommen bei XS-Barbershop in Langen! Entdecke unser Angebot an exklusiven Services für ein rundum gelungenes Erlebnis. Bei uns steht dein Wohlbefinden an erster Stelle. XS-Barbershop – Dein Ort zum Entspannen.', 
      className: 'hero', 
      containerClassName: 'hero-container', 
      buttonClass: 'btn-dark', 
      buttonText: '', 
      buttonLink: '', 
      imgClassName: '', 
      backgroundImage: require('../images/hero-bg.jpg'),
      cssid: 'hero-home',  
      id: 1 
    },
]

export const ServicespageCta = [
  {
    headline: 'Schön, dass Sie hier sind!',
    description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
    className: 'cta full',
    containerClassName: 'cta-container default-shadow',
    buttonClass: 'btn-outline',
    buttonText: 'Anfrage',
    buttonLink: '/contact',
    imgClassName: '',
    id: 1,
    section: 1,
  },
  {
    headline: 'Du hast eine spezielle Anfrage oder findest eine gewünschte Leistung nicht?',
    description: 'Wir sind flexibel und stehen bereit, deine individuellen Wünsche zu besprechen. Kontaktiere uns gerne, damit wir gemeinsam eine Lösung finden können, die perfekt zu deinen Bedürfnissen passt.',
    className: 'cta full',
    containerClassName: 'cta-container default-shadow',
    buttonClass: 'btn-outline',
    buttonText: 'Kontakt aufnehmen',
    buttonLink: '/contact',
    imgClassName: '',
    id: 2,
    section: 2,
  },
]

export const ServicespageHeadline = [
    {
      className: 'headline headline-center', 
      containerClassName: 'headline-container', 
      headline: 'Übersicht', 
      id: 1, 
      section: 1
    },
    {
      className: 'headline headline-center max-width', 
      containerClassName: 'headline-container', 
      headline: 'Alle Leistungen', 
      id: 2, 
      section: 2
    },

  ]

export const ServicespageModal = [

    {
      className: 'modal modal-center', 
      containerClassName: 'modal-container', 
      title: 'Welcome on my website!', 
      text: 'Nice to have you here. Please provide some information about yourself!', 
      textTwo: 'If you do not want to provide personal information, simply close this dialog. You can edit your settings at any time by clicking the edit button on top of this page.', 
      button: 'Enter your name', 
      id: 1, 
      section: 1},
  ]

export const ServicespageMeta = ''

export const ServicespageSlides = [
  {
    url: require('../images/slide-1.jpg'),
    alt: 'logo',
    id: 1
  },
  {
    url: require('../images/slide-1.jpg'),
    alt: 'logo',
    id: 2
  },
  {
    url: require('../images/slide-1.jpg'),
    alt: 'logo',
    id: 3
  },
  {
    url: require('../images/slide-1.jpg'),
    alt: 'logo',
    id: 4
  },
  {
    url: require('../images/slide-1.jpg'),
    alt: 'logo',
    id: 5
  },
  {
    url: require('../images/slide-1.jpg'),
    alt: 'logo',
    id: 6
  }
]

export const ServicespageHighlights = [
  {
    title: 'VIP-Behandlung',
    icon: 'fas fa-scissors',
    className: 'highlight one-quarter default-shadow',
    containerClassName: 'highlight-container',
    description: 'Inkl. Waschen, Schneiden, Föhnen, Bartpflege Premium und Gesichtsbehandlung',
    category: 'VIP',
    price: '€ 120',
    id: 1
  },
  {
    title: 'Trockenhaarschnitt',
    icon: 'fas fa-scissors',
    className: 'highlight one-quarter default-shadow',
    containerClassName: 'highlight-container',
    description: 'Inkl. Schneiden, Föhnen, Stylen',
    category: 'Haare',
    price: '€ 36',
    highlight: 'Jetzt buchen!',
    serviceId: "39531",
    id: 2
  },
  {
    title: 'Waschen - Schneiden - Föhnen',
    icon: 'fas fa-scissors',
    className: 'highlight one-quarter default-shadow',
    containerClassName: 'highlight-container',
    description: 'Inkl. Waschen, Schneiden, Föhnen, Stylen',
    category: 'Haare',
    price: '€ 39',
    highlight: 'Jetzt buchen!',
    serviceId: "39530",
    id: 3
  },
  {
    title: 'Nassrasur',
    icon: 'fas fa-scissors',
    className: 'highlight one-quarter default-shadow',
    containerClassName: 'highlight-container',
    category: 'Bart',
    description: 'Klassische Nassrasur, Behandlung mit einer warmen Kompresse anschließend Rasur mit Messer und Rasierseife',
    price: '€ 36',
    highlight: 'Jetzt buchen!',
    serviceId: "39536",
    id: 4
  },
  {
    title: 'Bartpflege Basic',
    icon: 'fas fa-scissors',
    className: 'highlight one-quarter default-shadow',
    category: 'Bart',
    containerClassName: 'highlight-container',
    description: 'Barttrimmen inkl. Konturenrasur mit Rasiergel, Bart und Hautpflege',
    price: '€ 31',
    highlight: 'Jetzt buchen!',
    serviceId: "39537",
    id: 5
  },
  {
    title: 'Bartpflege Premium',
    icon: 'fas fa-scissors',
    className: 'highlight one-quarter default-shadow',
    category: 'Bart',
    containerClassName: 'highlight-container',
    description: 'Bart trimmen, klassische Nassrasur, Behandlung mit einem warmen/kalten Handtuch (mit ätherischen Ölen) anschließend Rasur mit dem Messer, Rasierseife und Vorbehandlung',
    price: '€ 39',
    serviceId: "68787",
    highlight: 'Jetzt buchen!',
    id: 6
  },
  {
    title: 'Kopfrasur',
    icon: 'fas fa-scissors',
    className: 'highlight one-quarter default-shadow',
    category: 'Bart',
    containerClassName: 'highlight-container',
    description:'Klassische Nassrasur, Behandlung mit einer warmen Kompresse anschließend Rasur mit Messer und Rasierseife.',
    price: '€ 31',
    serviceId: "68788",
    highlight: 'Jetzt buchen!',
    id: 7
  },
  {
    title: 'Augenbrauen',
    icon: 'fas fa-scissors',
    className: 'highlight one-quarter default-shadow',
    category: 'Kosmetik',
    containerClassName: 'highlight-container',
    description: 'Nur nach vorheriger Absprache',
    price: '€ 10',
    id: 8
  },
  {
    title: 'Blackmask',
    icon: 'fas fa-scissors',
    className: 'highlight one-quarter default-shadow',
    category: 'Kosmetik',
    containerClassName: 'highlight-container',
    description: 'Sorgt für ein glattes und spürbar weicheres Hautbild',
    price: '€ 42',
    id: 9
  },

]