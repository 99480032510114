import React, { useState, useRef } from "react";
import { useInView } from "framer-motion";
import "./usercard.css";

function Usercard(props) {
  const usercard = props.usercard;
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <>
      {usercard.map((usercard) => (
        <div
          key={usercard.id}
          style={{
            transform: isInView ? "none" : "translateY(100px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
          className={["usercard-container", "primary"].join(" ")}
          ref={ref}
          onClick={() => setIsVisible(!isVisible)}
        >
          <div className="usercard-image">
            <img
              className={isVisible ? "visible" : ""}
              alt="Daniel Büttner"
              src={usercard.image}
            />
          </div>
          <div
            className={["usercard-body", isVisible ? "visible" : ""].join(" ")}
          >
            <h3 className="usercard-title">{usercard.title}</h3>
            <p className={isVisible ? "visible" : ""}>{usercard.description}</p>
            <p className={isVisible ? "visible" : ""}>{usercard.subtext}</p>
          </div>
        </div>
      ))}
    </>
  );
}

export default Usercard;
