import { React } from "react";
import "./customization.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  Cta,
  Grid,
  Hero,
  Headline,
  Divider,
  Modal,
  Preloader,
  Userform,
} from "../../components";
import { HomepageModal } from "../../data/DataHomepage";
import {
  CustomizablepageUserform,
  CustomizablepageHero,
  CustomizablepageMeta,
  CustomizablepageHeadline,
  CustomizablepageCta,
} from "../../data/DataCustomizationpage";
import { CompanyName } from "../../data";
import useLoginStatus from "../../hooks/loginCheck";

const CompanyNameTitle = CompanyName;

function Customization() {
  const modal = HomepageModal;
  const hero = CustomizablepageHero;
  const meta = CustomizablepageMeta;
  const userform = CustomizablepageUserform;
  const headline = CustomizablepageHeadline
  const cta = CustomizablepageCta
  const isLoggedIn = useLoginStatus();

  return (
    <div className="content">
      {isLoggedIn && (
        <HelmetProvider>
          <Grid
            grid={{
              className: "page",
              pageName: "customization-page",
              isFull: true,
            }}
          >
            <Helmet>
              <html lang="de" />
              <title>{CompanyNameTitle} - Anpassung</title>
              <meta name="description" content={meta} />
            </Helmet>
            <Hero hero={hero} />
          </Grid>
          <Grid
            grid={{
              className: "page",
              pageName: "customization-page",
              isFull: false,
            }}
          >
             <Divider divider={{ dividerHeight: "50px", dividerHeightMobile: "25px", id: 1 }} />
            <Headline
              headline={headline.filter((headline) => headline.section === 1)}
            />
            <Divider divider={{ dividerHeight: "100px", dividerHeightMobile: "50px", id: 1 }} />
            <Userform
              customizable={true}
              editable={false}
              userform={userform}
            />
            <Divider divider={{ dividerHeight: "200px", dividerHeightMobile: "100px", id: 1 }} />
            <Cta cta={cta} />
            <Divider divider={{ dividerHeight: "200px", dividerHeightMobile: "100px", id: 1 }} />
            <Modal modal={modal.filter((modal) => modal.section === 1)} />
          </Grid>
          <Preloader />
        </HelmetProvider>
      )}
    </div>
  );
}

export default Customization;
