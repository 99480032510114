export const ProjectspageBox = [

  {
    title: 'Frisur Makeover',
    description: 'Erlebe ein cooles Frisur-Makeover in unserem Barbershop! Lass dich von unseren Barbieren beraten und finde deinen neuen Look. Komm vorbei und gönn dir eine frische Frisur!',
    secondDescription: '',
    buttonText: '',
    buttonClassname: 'btn-dark',
    className: 'box no-padding two-thirds',
    headlineClassname: 'box-headline headline-bright',
    textClassname: 'box-text text-bright',
    imgpath: '',
    imgClassName: 'box-img box-img-rounded',
    id: 17,
    section: 1
  },
  {
    title: 'Bart Makeover',
    description: 'Verpass deinem Bart einen neuen Look bei uns! Unsere Barbiere finden den perfekten Stil für dich. Komm vorbei und gönn dir einen frischen Bart!',
    secondDescription: '',
    buttonText: '',
    buttonClassname: 'btn-dark',
    className: 'box no-padding two-thirds',
    headlineClassname: 'box-headline headline-bright',
    textClassname: 'box-text text-bright',
    imgpath: '',
    imgClassName: 'box-img box-img-rounded',
    id: 17,
    section: 2
  },
{
  title: 'Full Makeover',
  description: 'Gönn dir einen komplett neuen Look bei uns! Check die Vorher-Nachher-Bilder in unserer Galerie und lass dich inspirieren. Unsere Barbiere verpassen dir eine frische Frisur und einen stylischen Bart. Worauf wartest du?',
  secondDescription: '',
  buttonText: '',
  buttonClassname: 'btn-dark',
  className: 'box no-padding two-thirds',
  headlineClassname: 'box-headline headline-bright',
  textClassname: 'box-text text-bright',
  imgpath: '',
  imgClassName: 'box-img box-img-rounded',
  id: 17,
  section: 3
},
]

export const ProjectspageHero = [

  {
    title: 'Galerie',
    description: 'Entdecke unsere Galerie! Hier zeigen wir verschiedene Haarschnitte und Bartfrisuren unserer Kunden. Lass dich inspirieren und finde deinen perfekten Look bei uns.',
    className: 'hero',
    containerClassName: 'hero-container',
    buttonClass: 'btn-dark',
    buttonText: '',
    buttonLink: '',
    imgClassName: '',
    backgroundImage: require('../images/hero-bg.jpg'),
    cssid: 'hero-home',
    id: 1
  },
]

export const ProjectspageCta = [

  {
    headline: 'Online-Termin',
    description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et',
    className: 'cta full',
    containerClassName: 'cta-container default-shadow',
    buttonClass: 'btn-outline',
    buttonText: 'Jetzt Termin buchen!',
    buttonLink: '/projects',
    imgClassName: '',
    id: 1,
    section: 1,
  },
]

export const ProjectspageHighlights = [

  {
    title: 'Headline of Highlights Element',
    icon: 'fas fa-file',
    className: 'highlight',
    containerClassName: 'highlight-container',
    id: 1
  },
  {
    title: 'Headline of Highlights Element',
    icon: 'fas fa-file',
    className: 'highlight',
    containerClassName: 'highlight-container',
    id: 2
  },
  {
    title: 'Headline of Highlights Element',
    icon: 'fas fa-file',
    className: 'highlight',
    containerClassName: 'highlight-container',
    id: 3
  },
  {
    title: 'Headline of Highlights Element',
    icon: 'fas fa-file',
    className: 'highlight',
    containerClassName: 'highlight-container',
    id: 4
  },
  {
    title: 'Headline of Highlights Element',
    icon: 'fas fa-file',
    className: 'highlight',
    containerClassName: 'highlight-container',
    id: 5
  },
]

export const ProjectspageHeadline = [
  {
    className: 'headline headline-center',
    containerClassName: 'headline-container',
    headline: 'Vorher - Nachher',
    id: 1,
    section: 1
  },
  {
    className: 'headline headline-center',
    containerClassName: 'headline-container',
    headline: 'Weitere Bilder',
    id: 2,
    section: 2
  },

]


export const ProjectspageMeta = ''

export const ProjectspageSlides = [
  {
    url: require('../images/slider_before.jpg'),
    captionText: 'Vorher',
    alt: 'logo',
    id: 1
  },
  {
    url: require('../images/slider_after.jpg'),
    captionText: 'Nachher',
    alt: 'logo',
    id: 2
  },
  
]