import { useRef } from "react";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import { motion } from 'framer-motion';

const AccordionItem = ({ faq, accordion, toggle, open, fromJson }) => {
  const contentEl = useRef();

  const variants = {
    open: { height: "auto", opacity: 1 },
    closed: { height: 0, opacity: 0 }
  };

  if (fromJson)
    return (
      <div className={`accordion-item ${open ? "active" : ""}`}>
        <div className="accordion-title" onClick={() => toggle(faq.title)}>
          <h6 className="accordion-title">{faq.title}</h6>
          <span className="control title-icon">{open ? <AiOutlineMinusCircle /> : <AiOutlinePlusCircle />}</span>
        </div>
        <motion.div
          initial="closed"
          animate={open ? "open" : "closed"}
          variants={variants}
          ref={contentEl}
          className="accordion-content-container"
        >
          <div className="accordion-content-element"><p>{faq.content}</p></div>
        </motion.div>
      </div>
    );

  if (!fromJson)
    return (
      <div className={`accordion-item ${open ? "active" : ""}`}>
        <div className="accordion-title" onClick={() => toggle(accordion.title)}>
          <h6 className="accordion-title">{accordion.title}</h6>
          <span className="control title-icon">{open ? <AiOutlineMinusCircle /> : <AiOutlinePlusCircle />}</span>
        </div>
        <motion.div
          initial="closed"
          animate={open ? "open" : "closed"}
          variants={variants}
          ref={contentEl}
          className="accordion-content-container"
        >
          <div className="accordion-content-element"><p dangerouslySetInnerHTML={{ __html: accordion.content }}></p></div>
        </motion.div>
      </div>
    );
};

export default AccordionItem;
