import React, { useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./fullscreensection.css";
import { useIsDesktop } from "../../hooks/mediaQuery";

function Fullscreensection({ sections }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const fullscreenContainerRef = useRef(null); 
  const isDesktop = useIsDesktop()

  const scrollToTop = () => {
    const element = fullscreenContainerRef.current;
    let offset
    isDesktop ? offset = 40 : offset = 38

    if (element) {
      window.scrollTo({
        top: element.offsetTop + offset ,
        behavior: "smooth",
      });
    }
  };

  const refs = useRef([]);

  const goToNext = () => {
    const isLastSlide = currentIndex === sections.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    scrollToTop();
   
  };

  function setSectionIndex(sectionIndex) {
    setCurrentIndex(sectionIndex);
    scrollToTop();
   
  }

  return (
    <>
      <div className="fullscreen-section-container" ref={fullscreenContainerRef}>
        {sections.map((section, sectionIndex) => (
          <AnimatePresence key={section.id}>
            {sectionIndex === currentIndex && (
              <motion.div
                ref={(section) => (refs.current[sectionIndex] = section)}
                className={[
                  "fullscreen-section",
                  section.backgroundImage ? "fullscreen-background" : null,
                ].join(" ")}
                style={{
                  backgroundColor: section.backgroundColor
                    ? `${section.backgroundColor}`
                    : null,
                  boxShadow: section.backgroundImage
                    ? "rgb(0 0 0 / 51%) 0px 0px 0px 2000px inset"
                    : null,
                  background: section.backgroundImage
                    ? `url(${section.backgroundImage})`
                    : null,
                  position: "absolute", // Ensure sections overlap
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
                initial={{ opacity: 0, y: -500 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 500 }}
                transition={{ duration: 0.25 }}
              >
                <div className="fullscreen-section-content">
                  <div>
                    {section.title && (
                      <h1 className="fullscreen-section-title">
                        {section.title}
                      </h1>
                    )}
                    {section.description && <p>{section.description}</p>}
                  </div>
                </div>
                <div
                  onClick={() => goToNext(sectionIndex)}
                  className="section-arrow-down"
                >
                  <i className="fas fa-angle-down"></i>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        ))}
        <div className="fullscreen-navigation-container">
          {sections.map((section, sectionIndex) => (
            <div key={section.id} className="fullscreen-navigation-items">
              <i
                className={[
                  sectionIndex === currentIndex
                    ? "fas fa-circle"
                    : "fas fa-circle-notch",
                ].join(" ")}
                onClick={() => setSectionIndex(sectionIndex)}
              ></i>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Fullscreensection;
