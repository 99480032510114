import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import "./toast.css";

function Toast({ message, duration, link }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, duration);

    const hideTimer = setTimeout(() => {
      setVisible(false);
    }, duration + 5000); // 5 Sekunden nach dem Anzeigen

    return () => {
      clearTimeout(timer);
      clearTimeout(hideTimer);
    };
  }, [duration]);

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          className="toast"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -50 }}
          transition={{ duration: 0.5 }}
        >
          <Link to='/termin' className="toast-link btn-highlight">
            <i className="fas fa-calendar"></i> {message}
          </Link>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Toast;
