import { React } from "react";
import { Link } from 'react-router-dom';
import "./totopbutton.css";

function ToTopButtonItems(props) {
  const totopbuttonitems = props.totopbuttonitems;
  const scrollToTop = () => {
      window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  
  return (
    <div
      className="to-top-button-items-container"
      id="to-top-button-items-container"
    >
      <>
        {totopbuttonitems.map((totopbuttonitems) => (
          <div className="to-top-button-items" key={totopbuttonitems.id}>
          {totopbuttonitems.link && (<Link to={totopbuttonitems.link}><i className={totopbuttonitems.classname}></i></Link>)}
          {totopbuttonitems.externallink && (<a href={totopbuttonitems.externallink}><i onClick={totopbuttonitems.function ? scrollToTop : null} className={totopbuttonitems.classname}></i></a>)}
          {totopbuttonitems.totopbutton && (<i onClick={totopbuttonitems.function ? scrollToTop : null} className={totopbuttonitems.classname}></i>)}
           
          </div>
        ))}
      </>
    </div>
  );
}

export default ToTopButtonItems;
