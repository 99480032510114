import React from "react";
import "./grid.css";

function Grid({children, grid}) {
  return (
    <div className={[grid.className, grid.pageName, grid.isFull ? "fullwidth" : "", grid.vh100? "vh100" : ""].join(" ")} style={{minHeight : grid.minHeight}}>
        {children}
    </div>
  )
}

export default Grid