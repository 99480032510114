import React from "react";
import { NavLink } from "react-router-dom";




function NavigationItems({ navigationitems }) {

  return (
    <>
      {navigationitems.map((navigationitems, i) => (
       <p key={navigationitems.id}><NavLink key={navigationitems.id} to={navigationitems.link} className={({ isActive }) => (isActive ? 'active' : 'inactive')}> <i className={navigationitems.icon} /><span>{navigationitems.title}</span></NavLink></p>
      ))}
    </>
  );
}

export default NavigationItems








