import React, { useState, useRef, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import "./navigationbar.css";
import { NavigationItems, NavigationButtons } from "../../components";
import { NavButtons, NavItems } from "../../data/DataNavbar";
import useLoginStatus from "../../hooks/loginCheck";
import useWindowDimensions from "../../hooks/windowWidth";
const logoDark = require("../../images/XS-Logo-Black.png");
const logoLight = require("../../images/XS-Logo-White.png");

function NavigationBar() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [menuPageTitle, setMenuPageTitle] = useState("");
  const menuRef = useRef();
  const [navigationitems] = useState(NavItems);
  const [navigationbuttons] = useState(NavButtons);
  const [customLogo, setCustomLogo] = useState("");
  const [logo, setLogo] = useState(logoDark);
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const useCustomLogo = false;
  const location = useLocation();
  const pathname = location.pathname;
  const pagename = pathname.replace("/", "");
  const isLoggedIn = useLoginStatus();
  const [isMobile, setIsMobile] = useState(false);
  const screenSize = useWindowDimensions();
  const deviceWidth = screenSize.width;

  useEffect(() => {
    if (deviceWidth <= 1500) {
      setIsMobile(true);
    }
    return () => {
      setIsMobile(false);
    };
  }, [deviceWidth]);

  function scrollToTop() {
    if (pathname === "/" && scrolled) {
      window.scrollTo({
        top: height,
        behavior: "smooth",
      });
    }
  }

  function handleLogo() {
    if (localStorage.getItem("theme") === "dark") {
      setLogo(logoLight);
    } else setLogo(logoDark);
  }

  function handleTouchStart(e) {
    setTouchStart(e.targetTouches[0].clientX);
  }

  function handleTouchMove(e) {
    setTouchEnd(e.targetTouches[0].clientX);
  }

  function handleTouchEnd() {
    if (touchStart - touchEnd > 125) {
      setToggleMenu(true);
    }

    if (touchStart - touchEnd < -125) {
      setToggleMenu(false);
    }
  }
  useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setHeight(ref.current.offsetHeight);
      localStorage.setItem("navbarheight", height);
      window.dispatchEvent(new Event("storage"));
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, [height]);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 40) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("storage", setCustomImage);

    function setCustomImage() {
      var canvas = document.createElement("CANVAS");
      var ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      var initials = localStorage.getItem("initials");
      if (initials) {
        ctx.canvas.width = 200;
        ctx.canvas.height = 200;
        ctx.font = "bold 80px Quicksand";
        ctx.fillStyle = "#576f72";
        ctx.strokeStyle = "#576f72";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.beginPath();
        ctx.lineWidth = 8;
        ctx.arc(100, 95, 75, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.fillText(initials, canvas.width / 2, canvas.height / 2);
        let canvasUrl = canvas.toDataURL();
        localStorage.setItem("logoCustom", canvasUrl);
        window.dispatchEvent(new Event("newlogo"));
      }
    }
  });

  useEffect(() => {
    switch (pagename) {
      case "termin":
        setMenuPageTitle("Termin");
        break;
      case "about":
        setMenuPageTitle("Über Uns");
        break;
      case "services":
        setMenuPageTitle("Leistungen");
        break;
      case "projects":
        setMenuPageTitle("Galerie");
        break;
      case "contact":
        setMenuPageTitle("Kontakt");
        break;
      case "customization":
        setMenuPageTitle("Anpassung");
        break;
      case "legal":
        setMenuPageTitle("Rechtliches");
        break;
      case "test":
        setMenuPageTitle("Test");
        break;
      default:
        setMenuPageTitle("Home");
    }
  }, [pagename]);

  let navbar = ["navbar"];
  if (scrolled) {
    navbar.push("scrolled");
  }

  const handleOutsideClick = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setToggleMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    window.addEventListener("storage", handleLogo);
    handleLogo();

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      window.addEventListener("storage", handleLogo);
    };
  });

  useEffect(() => {
    const logoCustom = localStorage.getItem("logoCustom");
    const initials = localStorage.getItem("initials");
    if (logoCustom !== null && initials !== null && useCustomLogo) {
      setCustomLogo(logoCustom);
    }
  }, [useCustomLogo]);

  window.addEventListener("storage", (e) => {
    const logoCustom = localStorage.getItem("logoCustom");
    if (logoCustom !== null && useCustomLogo) {
      setCustomLogo(logoCustom);
    } else {
      setCustomLogo("");
    }
  });

  return (
    <>
      {isLoggedIn && (
        <div className={navbar.join(" ")} id="navbar" ref={ref}>
          <div className="navbar-links">
            <div className="navbar-links_logo" onClick={scrollToTop}>
              <NavLink to="/">
                {customLogo ? (
                  <img id="main-logo" src={customLogo} alt="Logo" />
                ) : (
                  <img id="main-logo" src={logo} alt="Logo" />
                )}
              </NavLink>
            </div>
            {isMobile && (
              <span
                className={[
                  "navbar-current-page",
                  "hidden-element",
                  scrolled ? "shown-element" : "",
                ].join(" ")}
              >
                {menuPageTitle}
              </span>
            )}
            <div className="navbar-links_container">
              <NavigationItems navigationitems={navigationitems} />
            </div>
          </div>
          <div className="navbar-sign">
            <NavigationButtons navigationbuttons={navigationbuttons} />
          </div>
          <div className="navbar-menu">
            <div
              className="navbar-menu__icon-container"
              onClick={() => setToggleMenu(!toggleMenu)}
            >
              <i className="fas fa-bars" />
            </div>
            <AnimatePresence>
              {toggleMenu && (
                <>
                  <motion.div
                    className="navbar-menu_container-overlay"
                    initial={{ opacity: 0, y: -150, scale: 1, borderRadius: 0 }}
                    animate={{ opacity: 1, y: 0, scale: 1, borderRadius: 0 }}
                    exit={{
                      opacity: 0,
                      y: 0,
                      scale: 0.25,
                      borderRadius: "var(--button-border-radius)",
                    }}
                    transition={{ duration: 0.25 }}
                  >
                    <div
                      className="navbar-menu_container"
                      id="navbar-menu_container"
                      onTouchStart={handleTouchStart}
                      onTouchMove={handleTouchMove}
                      onTouchEnd={handleTouchEnd}
                      ref={menuRef}
                    >
                      <div
                        className="navbar-menu_container-links"
                        onClick={() =>
                          setTimeout(() => {
                            setToggleMenu(false);
                          }, 50)
                        }
                      >
                        <img id="main-logo" src={logo} alt="Logo" />
                        <NavigationItems
                          toggleMenu={toggleMenu}
                          navigationitems={navigationitems}
                        />
                        <div className="navbar-menu_container-links-sign">
                          <NavigationButtons
                            navigationbuttons={navigationbuttons}
                          />
                        </div>
                      </div>
                      {toggleMenu && (
                        <div
                          className={`navbar-menu-container-side-toggle ${
                            toggleMenu ? "border" : ""
                          }`}
                          onClick={() => setToggleMenu(false)}
                        >
                          <i className="fas fa-xmark" />
                        </div>
                      )}
                    </div>
                  </motion.div>
                </>
              )}
            </AnimatePresence>
          </div>
        </div>
      )}
    </>
  );
}

export default NavigationBar;
