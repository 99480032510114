import { Fragment, React, useRef } from "react";
import "./parallax.css";
import { motion, useScroll, useTransform } from "framer-motion";
const Parallax = (props) => {
  const parallaxData = props.parallax;
  return (
    <>
      {parallaxData.map((parallaxData) => (
        <Fragment key={parallaxData.id}>
          <div className="parallax-container">
            <div className="fullscreen-container">
              
              <FullScreenOverlay
                headlineOverlay={parallaxData.headlineOverlay}
                descriptionOverlay={parallaxData.descriptionOverlay}
              />
              <FullScreenProductImage productImageUrl={parallaxData.productImageUrl} />
              <FullScreenImage
                url={parallaxData.url}
                productImageUrl={parallaxData.productImageUrl}
              />
            </div>
            <FullScreenContent
              headline={parallaxData.headline}
              description={parallaxData.description}
            />
          </div>
        </Fragment>
      ))}
    </>
  );
};
const FullScreenImage = ({ url }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });
  const scale = useTransform(scrollYProgress, [0, 0.5, 1], [1, 1, 0.8]);
  const opacity = useTransform(scrollYProgress, [0, .45, .5, 1], [1, 1, 0, 0]);
  const borderRadius = useTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, 1],
    ["0", "0", "0", "1.5rem", "1.5rem"]
  );

  return (
    <motion.div
      alt=""
      className="fullscreen-image"
      ref={ref}
      style={{
        backgroundImage: `url(${url})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "calc(100vh - 56px)",
        top: "0px",
        position: "sticky",
        zIndex: "0",
        marginTop: "0rem",
        scale,
        borderRadius,
        filter: "grayscale(1)",
      }}
    >
      <motion.div
        style={{
          position: "absolute",
          inset: "0",
          backgroundColor: "#000000c7",
          opacity,
          borderRadius,
        }}
      ></motion.div>
    </motion.div>
  );
};

const FullScreenContent = ({ headline, description }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end start", "end end"],
  });
  const scale = useTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.85, 1],
    [1, 1, 1, 1, 0.8]
  );
  const opacity = useTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.85, 1],
    [1, 1, 1, 1, 0]
  );
  const translateY = useTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.85, 1],
    ["0%", "0%", "0%", "0%", "150%"],
    { clamp: false }
  );

  return (
    <motion.div
      ref={ref}
      className="fullscreen-description"
      style={{ scale, translateY, opacity }}
    >
      <h2>{headline}</h2>
      <p>{description}</p>
    </motion.div>
  );
};

const FullScreenOverlay = ({ headlineOverlay, descriptionOverlay }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "center start"],
  });
  const opacity = useTransform(
    scrollYProgress,
    [0, 0.5, 0.65, 1],
    [1, 1, 0, 0]
  );
  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.8]);
  return (
    <motion.div
      ref={ref}
      className="fullscreen-description-overlay"
      style={{ scale }}
    >
      <motion.h4 style={{ fontWeight: "400", opacity }} className="text-dark">
        {descriptionOverlay}
      </motion.h4>
      <motion.h1 style={{ opacity }} className="text-dark">
        {headlineOverlay}
      </motion.h1>
    </motion.div>
  );
};

const FullScreenProductImage = ({ productImageUrl }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(
    scrollYProgress,
    [0, 0.5, 0.6, 0.85, 1],
    [.5, 1, 1, 1, 0.6]
  );
  const translateX = useTransform(
    scrollYProgress,
    [0, 0.25, 0.75, 0.95, 1],
    ["0%", "0%", "0%", "0%", "0%"],
    { clamp: false }
  );
  const translateY = useTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.75, .95, 1],
    ["25%", "25%", "60%", "100%", "100%", "150%"],
    { clamp: false }
  );
  const opacity = useTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.99, 1],
    [1, 1, 1, 1, 1]
  );
  const rotate = useTransform(
    scrollYProgress,
    [0, 0.25, 0.5, 0.8, 1],
    ["0deg", "0deg", "0deg", "-90deg", "-90deg"]
  );


  return (
    <motion.div
      ref={ref}
      style={{ scale, translateX, translateY, opacity, rotate }}
      className="fullscreen-product-image"
    >
      <img src={productImageUrl} alt="" />
    </motion.div>
  );
};

export default Parallax;
