

export const NotfoundpageHero = [

    { 
      title: '404 - Nicht verfügbar', 
      description: 'Entschuldigung, die Seite konnte nicht gefunden werden. Möglicherweise ist der Link falsch oder die Seite wurde verschoben. Bitte überprüfe  die URL und versuche es erneut.', 
      className: 'hero', 
      containerClassName: 'hero-container', 
      buttonClass: 'btn-dark', 
      buttonText: '', 
      buttonLink: '#', 
      imgClassName: '', 
      backgroundImage: require('../images/hero-bg.jpg'),
      cssid: 'hero-home',  
      id: 1 
    },
]

export const NotfoundpageMeta = ''

