import React, { useState } from "react";
import "./roundednavbar.css";
import { Link } from "react-router-dom";

function RoundedNavbar({ menuItem }) {
  const [isActive, setIsActive] = useState(false);
  function setActive() {
    setIsActive(!isActive);
  }

  return (
    <>
      <nav className="rounded-navbar-container">
        <button
          onClick={setActive}
          className={`rounded-navbar-toggle-btn ${
            isActive ? "active" : ""
          }`}
        >
          <i className="fas fa-home" />
        </button>
        {menuItem.map((menuItem, index) => (
          <>
            {isActive && (
              <Link className={`rounded-navbar-item ${
                isActive ? "visible" : ""
              }`} key={index} to={menuItem.link}>
                <i className={menuItem.icon} />
              </Link>
            )}
          </>
        ))}
      </nav>
    </>
  );
}

export default RoundedNavbar;
