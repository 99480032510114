import React, { useState, useRef } from "react";
import { useInView } from "framer-motion";
import { motion, AnimatePresence } from "framer-motion";
import "./slider.css";

const Slider = ({ slides, size }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setDirection(isFirstSlide ? 1 : -1); // Adjust direction for looping
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setDirection(isLastSlide ? -1 : 1); // Adjust direction for looping
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setDirection(slideIndex > currentIndex ? 1 : -1);
    setCurrentIndex(slideIndex);
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 50) {
      goToNext();
    }

    if (touchStart - touchEnd < -50) {
      goToPrevious();
    }
  };

  const sliderCaption = slides[currentIndex]?.captionText;

  return (
    <div
      key={slides.id}
      style={{
        transform: isInView ? "none" : "translateY(100px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
      }}
      className="slider-container"
      ref={ref}
    >
      <div>
        <div onClick={goToPrevious} className="slider-arrow-left">
          <i className="fas fa-angle-left"></i>
        </div>
        <div onClick={goToNext} className="slider-arrow-right">
          <i className="fas fa-angle-right"></i>
        </div>
      </div>
      <div className="slider-slide-container">
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            key={currentIndex}
            className="slider-slide"
            style={{
              backgroundImage: `url(${slides[currentIndex].url})`,
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
            custom={direction}
            initial={{ x: direction === 1 ? '100%' : '-100%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: direction === 1 ? '100%' : '-100%', opacity: 0 }}
            transition={{ duration: 0.5 }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          ></motion.div>
        </AnimatePresence>
      </div>
      {sliderCaption && <span className="slider-caption">{sliderCaption}</span>}
      <div className="slider-dots-container">
        {slides.map((slide, slideIndex) => (
          <div
            id="slider-dots-dot"
            key={slide.id}
            onClick={() => goToSlide(slideIndex)}
          >
            <i
              className={
                currentIndex === slideIndex
                  ? "fas fa-circle"
                  : "fas fa-circle-notch"
              }
              key={slide.id}
            ></i>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
