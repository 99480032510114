export const InfopageAccordions = [
  {
    title: 'Online Termin buchen', 
    content: 'Nutzen Sie folgenden Link, um einen Termin zu buchen:<p> <a href="https://www.etermin.net/xs-barbershop/1000">Zum Online-Terminkalender</a></p>', 
    id: 3
  },
    {
      title: 'E-Mail', 
      content: 'Kontaktieren Sie uns via E-Mail: <a href="mailto:info@xs-barbershop.com">info@xs-barbershop.com</a>', 
      id: 1
    },
    {
      title: 'Telefon', 
      content: 'Kontaktieren Sie uns via Telefon: <a href="tel:061034877822">06103 4877822</a>', 
      id: 2
    }
  ]