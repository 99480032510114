import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { TbRazor } from "react-icons/tb";
import { RiScissorsLine } from "react-icons/ri";
import { FaMask } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./highlights.css";

function Highlights(props) {
  const { highlights, isBookable } = props;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const navigate = useNavigate();

  const getCategoryIcon = (category) => {
    switch (category) {
      case "VIP":
        return <FaStar />;
      case "Haare":
        return <RiScissorsLine />;
      case "Bart":
        return <TbRazor />;
      case "Kosmetik":
        return <FaMask />;
      default:
        return null;
    }
  };

  const handleHighlightClick = (serviceId) => {
    if (isBookable && serviceId) {
      navigate(`/termin?serviceId=${serviceId}`);
    }
  };

  return (
    <>
      <div ref={ref} className="highlights">
        {highlights.map((highlight) => (
          <div
            key={highlight.id}
            style={{
              transform: isInView ? "none" : "translateY(100px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
            className={[highlight.className, highlight.serviceId && "highlight-bookable"].join(" ")}
            onClick={() => handleHighlightClick(highlight.serviceId)}
          >
            <div className={highlight.containerClassName}>
              {highlight.icon && (
                <div>{getCategoryIcon(highlight.category)}</div>
              )}
              {highlight.title && <h4>{highlight.title}</h4>}
              {highlight.description && <p>{highlight.description}</p>}
              {highlight.highlight && (
                <p className="hightlight-flag">{highlight.highlight}</p>
              )}
              {highlight.price && (
                <p className="hightlight-price">{highlight.price}</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Highlights;
