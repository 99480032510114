import React, { useState, useEffect, useRef } from "react";
import { useInView, motion, AnimatePresence } from "framer-motion";
import "./gallery.css";

function Gallery(props) {
  const gallery = props.gallery;
  const [selectedImage, setSelectedImage] = useState("");
  const imageRef = useRef();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const showImage = (imageIndex) => {
    const img = gallery[imageIndex].image;
    setSelectedImage(img);
  };

  const hideImage = (e) => {
    if (imageRef.current && !imageRef.current.contains(e.target)) {
      setSelectedImage("");
    } else setSelectedImage("");
  };

  useEffect(() => {
    document.addEventListener("mousedown", hideImage);
    return () => {
      document.removeEventListener("mousedown", hideImage);
    };
  }, []);

  return (
    <>
      <div className="gallery" ref={ref}>
        {gallery.map((gallery, imageIndex) => (
          <motion.div
            key={gallery.id}
            className="gallery-image-container"
            style={{
              transform: isInView ? "none" : "translateY(100px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
          >
            <motion.img
              className={["gallery-image", gallery.className].join(" ")}
              alt={gallery.alt}
              src={gallery.image}
              onClick={() => showImage(imageIndex)}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            />
          </motion.div>
        ))}
      </div>

      <AnimatePresence>
        {selectedImage && (
          <motion.div
            className="gallery-fullscreen-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{
              opacity: 0,
            }}
            transition={{ duration: 0.5 }}
          >
            <motion.img
              ref={imageRef}
              className="gallery-image-fullscreen"
              src={selectedImage}
              alt="Selected"
              initial={{ opacity: 0, scale: 1 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{
                opacity: 0,
                scale: 0.25,
              }}
              transition={{ duration: 0.25 }}
            />
            <button onClick={hideImage} className="btn btn-dark">
              Schließen
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default Gallery;
