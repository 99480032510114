export const TerminpageBox = [
  {
    title: '',
    description: '',
    secondDescription: '',
    className: 'box half box-dark',
    headlineClassname: 'box-headline headline-bright',
    textClassname: 'box-text text-bright',
    imgpath: require('../images/box-home-1.jpg'),
    buttonText: '',
    buttonClassname: 'btn-dark',
    linkClassname: 'btn-dark',
    linkName: '',
    linkTarget: '',
    imgClassName: 'box-img box-img-rounded box-img-no-margin',
    id: 1,
    section: 1
  },
  {
    title: '',
    description: '',
    secondDescription: '',
    className: 'box half box-dark',
    headlineClassname: 'box-headline headline-bright',
    textClassname: 'box-text text-bright',
    imgpath: require('../images/box-home-2.jpg'),
    buttonText: '',
    linkClassname: 'btn-dark',
    linkName: '',
    linkTarget: '',
    imgClassName: 'box-img box-img-rounded box-img-no-margin',
    id: 2,
    section: 1
  },
  {
    title: '',
    description: '',
    secondDescription: '',
    buttonText: 'Alle Leistungen',
    buttonLink: '/services',
    buttonClassname: 'btn-outline',
    className: 'box box-dark one-third',
    headlineClassname: 'box-headline headline-bright',
    textClassname: 'box-text text-bright',
    imgpath: require('../images/box-home-3.jpg'),
    imgClassName: 'box-img box-img-rounded box-img-no-margin',
    id: 3,
    section: 1
  },
  {
    title: '',
    description: '',
    secondDescription: '',
    buttonText: 'Alle Leistungen',
    buttonLink: '/services',
    buttonClassname: 'btn-outline',
    className: 'box box-dark one-third',
    headlineClassname: 'box-headline headline-bright',
    textClassname: 'box-text text-bright',
    imgpath: require('../images/box-home-4.jpg'),
    imgClassName: 'box-img box-img-rounded box-img-no-margin',
    id: 11,
    section: 1
  },
  {
    title: '',
    description: '',
    secondDescription: '',
    buttonText: 'Alle Leistungen',
    buttonLink: '/services',
    buttonClassname: 'btn-outline',
    className: 'box box-dark one-third',
    headlineClassname: 'box-headline headline-bright',
    textClassname: 'box-text text-bright',
    imgpath: require('../images/box-home-5.jpg'),
    imgClassName: 'box-img box-img-rounded box-img-no-margin',
    id: 12,
    section: 1
  },
  {
    title: '',
    description: '',
    secondDescription: '',
    className: 'box box-dark full',
    headlineClassname: 'box-headline headline-bright',
    textClassname: 'box-text text-bright',
    linkClassname: 'btn-dark',
    linkName: 'Sprechen Sie uns an!',
    linkTarget: '/contact',
    imgpath: require('../images/box-home-6.jpg'),
    imgClassName: 'box-img box-img-rounded box-img-no-margin',
    id: 13,
    section: 1
  },
  

]

export const TerminpageHero = [
  { 
    title: 'Termin buchen', 
    description: 'Buche noch heute deinen Termin im XS-Barbershop in Langen. Wir freuen uns auf deinen Besuch!', 
    className: 'hero', 
    containerClassName: 'hero-container', 
    buttonClass: 'btn-outline', 
    buttonText: '', 
    buttonLink: '', 
    imgClassName: '', 
    backgroundImage: require('../images/hero-bg.jpg'),
    cssid: 'hero-home', 
    id: 1 },
]

export const TerminpageCta = [
  {
    headline: 'Terminpage Call to Action Heading 1',
    description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
    className: 'cta full',
    containerClassName: 'cta-container default-shadow',
    buttonClass: 'btn-outline',
    buttonText: 'Jetzt Termin vereinbaren',
    buttonLink: '/projects',
    imgClassName: '',
    id: 1,
    section: 1,
  },
  {
    headline: 'Terminpage Call to Action Heading Kontakt',
    description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
    className: 'cta full',
    containerClassName: 'cta-container  default-shadow',
    buttonClass: 'btn-outline',
    buttonText: 'Anfrage',
    buttonLink: '/contact',
    imgClassName: '',
    id: 2,
    section: 2,
  },

]

export const TerminpageHighlights = [
  {
    title: 'VIP-Behandlung',
    icon: 'fas fa-check',
    className: 'highlight one-quarter default-shadow',
    containerClassName: 'highlight-container',
    id: 1
  },
  {
    title: 'Trockenhaarschnitt',
    icon: 'fas fa-check',
    className: 'highlight one-quarter default-shadow',
    containerClassName: 'highlight-container',
    id: 2
  },
  {
    title: 'Waschen - Schneiden - Föhnen',
    icon: 'fas fa-check',
    className: 'highlight one-quarter default-shadow',
    containerClassName: 'highlight-container',
    id: 3
  },
  {
    title: 'Nassrasur',
    icon: 'fas fa-check',
    className: 'highlight one-quarter default-shadow',
    containerClassName: 'highlight-container',
    id: 4
  },
  {
    title: 'Bartpflege Basic',
    icon: 'fas fa-check',
    className: 'highlight one-quarter default-shadow',
    containerClassName: 'highlight-container',
    id: 5
  },
  {
    title: 'Bartpflege Premium',
    icon: 'fas fa-check',
    className: 'highlight one-quarter default-shadow',
    containerClassName: 'highlight-container',
    id: 6
  },
  {
    title: 'Kopfrasur',
    icon: 'fas fa-check',
    className: 'highlight one-quarter default-shadow',
    containerClassName: 'highlight-container',
    id: 7
  },
  {
    title: 'Augenbrauen',
    icon: 'fas fa-check',
    className: 'highlight one-quarter default-shadow',
    containerClassName: 'highlight-container',
    id: 8
  },
  {
    title: 'Blackmask',
    icon: 'fas fa-check',
    className: 'highlight one-quarter default-shadow',
    containerClassName: 'highlight-container',
    id: 9
  },

]

export const TerminpageHeadline = [
  {
    className: 'headline headline-center',
    containerClassName: 'headline-container',
    headline: 'Übersicht unserer Leistungen',
    id: 1,
    section: 2
  },
]

export const TerminpageModal = [
  {
    className: 'modal modal-center',
    containerClassName: 'modal-container',
    title: 'Willkommen beim XS-Barbershop',
    text: 'Wir haben das Theme der Website basierend auf deinen Einstellungen automatisch festgelegt. Dieses kannst du über den Button unten wechseln. Außerdem stehen dir weitere Anpassungsmöglichkeiten zur Verfügung.',
    button: 'Namen eingeben',
    ctaText: 'Weitere Anpassungen',
    ctaIcon: 'fas fa-palette',
    ctaLink: '/customization',
    id: 1,
    section: 1
  },

]


export const TerminpageSlides = [
  {
    url: require('../images/slide-5.jpg'),
    alt: 'logo',
    id: 1
  },
  {
    url: require('../images/slide-2.jpg'),
    alt: 'logo',
    id: 2
  },
  {
    url: require('../images/slide-3.jpg'),
    alt: 'logo',
    id: 3
  },
  {
    url: require('../images/slide-4.jpg'),
    alt: 'logo',
    id: 4
  }
]

export const TerminpageSections = [
  {
    title: "Ambiente",
    backgroundImage: require('../images/slide-1.jpg'),
    description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    id: 0,
    
  },
  {
    title: "Team",
    backgroundImage: require('../images/slide-2.jpg'),
    description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
    id: 1
  },
  {
    title: "Über uns",
    backgroundImage: require('../images/slide-3.jpg'),
    description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    id: 2
  },
  {
    title: "Weiteres Highlight",
    backgroundImage: require('../images/slide-4.jpg'),
    description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
    id: 3
  }
]

export const TerminpageMeta = ''

