import React, { useRef, useEffect, useState, useMemo } from "react";
import "./fadetext.css";
import { motion, useScroll, useTransform } from "framer-motion";

const FadeText = ({ text }) => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({ target: containerRef });
  const opacity = useTransform(scrollYProgress, [0, 0.75, 1], [0, 1, 1]);

  // Use useMemo to derive words only when text changes
  const words = useMemo(() => text.split(" "), [text]);
  const [opacities, setOpacities] = useState(Array(words.length).fill(0.1));

  useEffect(() => {
    const updateOpacities = () => {
      const newOpacities = words.map((_, index) => {
        const start = index / (words.length - 1); // Relative position of the word
        const scrollProgress = scrollYProgress.get();

        // Adjust opacity logic
        if (scrollProgress > start) {
          return 1; // Fully visible
        } else if (scrollProgress === 0) {
          return 0; // Partially visible
        } else if (scrollProgress > start - 0.1) {
          return 1; // Partially visible
        } else {
          return 0; // Slightly visible
        }
      });

      setOpacities((prevOpacities) => {
        if (JSON.stringify(prevOpacities) !== JSON.stringify(newOpacities)) {
          return newOpacities;
        }
        return prevOpacities;
      });
    };

    const unsubscribe = scrollYProgress.on("change", updateOpacities);
    updateOpacities(); // Initial opacity update

    return () => unsubscribe();
  }, [scrollYProgress, words]); // Include words here

  return (
    <div
      className="text-reveal-container"
      ref={containerRef}
      style={{
        height: "200vh",
        width: "100%",
        backgroundColor: "#121212",
        position: "relative",
        overflow: "clip",
      }}
    >
      <div
        style={{
          position: "absolute",
          inset: "0",
        }}
      >
        <motion.div
          className="text-reveal-background"
          style={{
            position: "sticky",
            top: "0%",
            width: "100%",
            height: "100vh",
            backgroundImage:
              'url("/static/media/box-home-1.1dbedb921839c1955132.jpg")',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            filter: "grayscale(1)"
          }}
        />
      </div>
      <motion.div
        className="text-reveal-overlay"
        style={{
          opacity,
          position: "absolute",
          inset: "0",
          backgroundColor: "var(--background-color)",
        }}
      />
      <div
        className="text-reveal-content"
        style={{
          position: "sticky",
          top: "50%",
          transform: "translateY(-50%)",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexWrap: "wrap",
          padding: "2rem",
          margin: "0 auto 25% auto",
          maxWidth: "800px",
        }}
      >
        {words.map((word, index) => (
          <div
            key={index}
            style={{
              position: "relative",
              display: "inline-block",
              margin: "0 5px",
            }}
          >
            <motion.h1
              style={{
                color: "var(--tertiary-color)",
                opacity: opacities[index],
                transition: "opacity 0.25s ease-in-out",
              }}
            >
              {word}
            </motion.h1>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FadeText;
