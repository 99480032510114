import "./divider.css";
import useWindowDimensions from "../../hooks/windowWidth";
import { useState, useEffect } from "react";

function Divider(props) {
  const [isMobile, setIsMobile] = useState(false);
  const screenSize = useWindowDimensions();
  const deviceWidth = screenSize.width;

  useEffect(() => {
    if (deviceWidth <= 768) {
      setIsMobile(true);
    }
    return () => {
      setIsMobile(false);
    };
  }, [deviceWidth]);

  const divider = props.divider;
  return (
    <>
      <div
        className={["divider-container"].join(" ")}
        style={{
          height:
            divider.dividerHeight && !isMobile
              ? `${divider.dividerHeight}`
              : `${divider.dividerHeightMobile}`,
        }}
      ></div>
    </>
  );
}

export default Divider;
