export const CustomizablepageUserform = [
    {
      darkmodeswitch: 'true',
      colorchoice: 'true',
      savethemeoptions:'true',
      id: 1,
    }
]

export const CustomizablepageHeadline = [
  {
    className: 'headline headline-center',
    containerClassName: 'headline-container',
    headline: 'Anpassungen',
    id: 1,
    section: 1
  },
]

export const CustomizablepageBox = [
]

export const CustomizablepageHero = [
{
  title: "Anpassung",
  description: "So individuell wie unsere Haarschnitte ist auch unsere Website. Hier hast du die Möglichkeit, die Farben ganz nach deinem Geschmack anzupassen. Die Änderungen kannst natürlich nur du sehen.",
  className: "hero",
  containerClassName: "hero-container",
  backgroundImage: require('../images/hero-bg.jpg'),
  imgClassName: "",
  id: 1,
},
]

export const CustomizablepageCta = [
  {
    headline: 'Dir gefällt diese Website?',
    description: 'Ich bin Joshua Krieg, der Designer und Entwickler dahinter. Wenn du eine eigene und vor allem einzigartige Website brauchst, die dich von deiner Konkurrenz abhebt, melde dich einfach bei mir. Ich helfe dir gerne, deine Ideen umzusetzen.',
    className: 'cta full',
    containerClassName: 'cta-container default-shadow',
    buttonClass: 'btn-outline',
    buttonTextExt: 'Anfrage',
    buttonLinkExt: 'mailto:info@joshuakrieg.com',
    imgClassName: '',
    id: 1,
    section: 1,
  },
]
export const CustomizablepageMeta = ''

