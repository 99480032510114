import { useEffect } from "react";

const checkAndRemoveOldLocalStorageItems = () => {
  const itemKeys = ["modal_status", "toast_shown"];
  const dateKey = "current_date"; // Key for the date in localStorage
  const dateStr = localStorage.getItem(dateKey);

  if (!dateStr) {
    return; // Date item doesn't exist
  }

  const itemDate = new Date(dateStr);
  const now = new Date();
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

  // Check if the date is more than 1 day old
  if (now - itemDate > oneDayInMilliseconds) {
    itemKeys.forEach((key) => {
      localStorage.removeItem(key);
    });
    localStorage.removeItem(dateKey);
  }
};

const LocalStorageCheck = () => {
  useEffect(() => {
    checkAndRemoveOldLocalStorageItems();
  }, []);

  return null;
};

export default LocalStorageCheck;
