import { React, useRef } from "react";
import { useInView } from "framer-motion";
import "./headline.css";

function Headline(props) {
  const headline = props.headline;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <>
      {headline.map((headline) => (
        <div
          style={{
            transform: isInView ? "none" : "translateY(100px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
          key={headline.id}
          className={[headline.className].join(" ")}
          ref={ref}
        >
          <div className={headline.containerClassName}>
            {headline.headline && <h2>{headline.headline}</h2>}
          </div>
        </div>
      ))}
    </>
  );
}

export default Headline;
