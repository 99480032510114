// Replace with your actual API keys
const publicKey = 'F9A5AB83-71E4-4D2C-B131-491D77C4AF43';
const privateKey = '07EB7412';

const generateSignature = async (salt) => {
    const encoder = new TextEncoder();
    const key = await crypto.subtle.importKey(
      'raw',
      encoder.encode(privateKey),
      { name: 'HMAC', hash: 'SHA-256' },
      false,
      ['sign']
    );
    const signature = await crypto.subtle.sign('HMAC', key, encoder.encode(salt));
    const base64Signature = btoa(String.fromCharCode(...new Uint8Array(signature)));
    return base64Signature;
};

const apiRequest = async (url, method, data = null, additionalHeaders = {}) => {
    const salt = Array.from(crypto.getRandomValues(new Uint8Array(16))).map(b => b.toString(16).padStart(2, '0')).join('');
    const signature = await generateSignature(salt);

    const headers = {
        'publickey': publicKey,
        'salt': salt,
        'signature': signature,
        'Content-Type': 'application/json',
        ...additionalHeaders
    };

    const options = {
        method: method,
        headers: headers,
    };

    if (data) {
        options.body = data;
    }

    try {
        const response = await fetch(url, options);
        const contentType = response.headers.get("content-type");
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        if (contentType && contentType.includes("application/json")) {
            return await response.json();
        } else {
            return await response.text();
        }
    } catch (error) {
        console.error('API request error:', error);
        throw error;
    }
};

export default apiRequest;
