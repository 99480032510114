import { React, useRef } from "react";
import { useInView } from "framer-motion";
import "./timeline.css";

function Timeline(props) {
  const timeline = props.timeline;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <>
      <div className="timeline-outer-wrapper">
        <div className="timeline-inner-wrapper" ref={ref}>
          {timeline.map((timeline) => (
            <div
              key={timeline.id}
              style={{
                transform: isInView ? "none" : "translateY(100px)",
                opacity: isInView ? 1 : 0,
                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
              }}
              className={["timeline-container", "primary"].join(" ")}
            >
              <div className="timeline-icon">
                <i className={timeline.icon}></i>
              </div>
              <div className="timeline-body">
                <h2 className="timeline-title">
                  <span className="badge">{timeline.title}</span>
                </h2>
                <p>{timeline.description}</p>
                <p className="timeline-subtitle">{timeline.subtext}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Timeline;
