import React, { useState, useEffect } from "react";
import "./fullscreen.css";
import { Accordion } from "../../components";
import { InfopageAccordions } from "../../data/DataInfopage";

const logoDark = require("../../images/XS-Logo-Black.png");
const logoLight = require("../../images/XS-Logo-White.png");

function Fullscreen(props) {
  const [logo, setLogo] = useState(logoDark);
  const isActive = props.isLoggedIn;
  const [accordion] = useState(InfopageAccordions);
  const [password, setPassword] = useState("");
  const [isLoggedIn, setLogin] = useState(isActive);
  const queryParams = new URLSearchParams(window.location.search);
  const accessKey = queryParams.get("access_key");  

  useEffect(() => {
    if (accessKey === "456400" || isLoggedIn) {
      localStorage.setItem("isLoggedIn", "true");
    }
    if (localStorage.getItem("isLoggedIn") === "true") {
      setLogin(true);
    } else {
      setLogin(false);
    }
    
  }, [accessKey, isLoggedIn]);

  function unlockPage() {
    if (isLoggedIn || password === "456400") {
      setLogin(true);
      localStorage.setItem("isLoggedIn", "true");
      window.dispatchEvent(new Event("storage"));
    }
    
    else {
      document.getElementsByClassName(
        "fullscreen-password-message"
      )[0].innerHTML = "Der eingebene Code ist nicht korrekt!";
      localStorage.setItem("isLoggedIn", "false");
      setTimeout(addAnimation, 5)
    }
  } 
  function addAnimation() {
    const addanimation = document.getElementsByClassName(
      "fullscreen-unlock_button"
    )
    addanimation[0].classList.toggle("horizontal-shake")
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      unlockPage();
    }
  };

  function onChangePassword(e) {
    setPassword(e.target.value);
  }

  function handleLogo() {
    if (localStorage.getItem("theme") === "dark") {
      setLogo(logoLight);
    } else setLogo(logoDark);
  }

  useEffect(() => {
    window.addEventListener("storage", handleLogo);
    handleLogo();

    return () => {
      window.addEventListener("storage", handleLogo);
    };
  });

  return (
    <>
      {!isLoggedIn && (
        <div className="fullscreen-overlay">
          <div className="fullscreen-container">
            <div className="fullscreen-content">
              <img
                id="main-logo"
                className="fullscreen-overlay__logo"
                src={logo}
                alt="Logo"
              />
              <h2>Unsere Website wir derzeit überarbeitet.</h2>
              <p>
                <strong>
                  Wir bitten Sie, die Unannehmlichkeiten zu entschuldigen.
                </strong>
              </p>
              <p>Sie können weiterhin Online einen Termin buchen.</p>
              <p className="fullscreen-password-message">
                <strong></strong>
              </p>
              {<div className="fullscreen-unlock">
                <input
                  className="fullscreen-password"
                  required
                  type="password"
                  placeholder="Code eingeben"
                  value={password}
                  onChange={onChangePassword}
                  onSubmit={unlockPage}
                  onKeyDown={handleKeyDown}
                />
                <button className="fullscreen-unlock_button btn-highlight" onClick={unlockPage} onMouseEnter={unlockPage}>
                  FREISCHALTEN
                </button>
              </div>}
              <Accordion fromJson={true} accordion={accordion} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Fullscreen;
