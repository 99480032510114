import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence, useInView } from "framer-motion";
import apiRequest from "../../auth/auth";
import List from "../list/List";
import { TbRazor } from "react-icons/tb";
import { RiScissorsLine } from "react-icons/ri";
import { FaMask, FaStar } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { listItems } from "../../data/DataDefault";
import useOnlineStatus from "../../hooks/useOnlineStatus";
import Confetti from "../confetti/Confetti";
import "./booking.css";

const Booking = () => {
  // Check if connected
  const isOnline = useOnlineStatus();
  // Selected items from user
  const [selectedItems, setSelectedItems] = useState([]);
  // Selected timeslots from user
  const [timeslots, setTimeslots] = useState([]);
  // Ensure that inital selected day cannot be sunday or monday
  const getNextAvailableDay = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
  
    // If today is Sunday (0) or Monday (1), set the date to the next Tuesday (2)
    if (dayOfWeek === 0) {
      today.setDate(today.getDate() + 2);
    } else if (dayOfWeek === 1) {
      today.setDate(today.getDate() + 1);
    }
    return today.toISOString().split("T")[0];
  };
  // Selected date from user - default current date
  const [selectedDate, setSelectedDate] = useState(getNextAvailableDay());
  // State that user confirmed slot
  const [timeslotConfirmed, setTimeslotConfirmed] = useState(false);
  // State that user confirmed date
  const [dateConfirmed, setDateConfirmed] = useState(false);
  // State that user confirmed service
  const [serviceSelected, setServiceSelected] = useState(false);
  // State that appointment was created
  const [appointmentCreated, setAppointmentCreated] = useState(false);
  // State to show confetti after booking
  const [showConfetti, setShowConfetti] = useState(false);
  // State that user cancelled booking
  const [appointmentDeleted, setAppointmentDeleted] = useState(false);
  // State if data is loading
  const [loading, setLoading] = useState(false);
  // State that user selected Slot
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);
  // State for user details
  const [userDetails, setUserDetails] = useState({
    firstName: localStorage.getItem("firstname") || "",
    lastName: localStorage.getItem("lastname") || "",
    email: localStorage.getItem("mail") || "",
    phone: localStorage.getItem("phone") || "",
  });
  // State to validate form
  const [isFormValid, setIsFormValid] = useState(() => {
    const firstName = localStorage.getItem("firstname") || "";
    const lastName = localStorage.getItem("lastname") || "";
    const email = localStorage.getItem("mail") || "";
    const phone = localStorage.getItem("phone") || "";
    return firstName && lastName && email && phone;
  });
  // State that form is submitted
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  // State to show waitlist input fields
  const [showWaitlistTimeInput, setShowWaitlistTimeInput] = useState(false);
  // State waitlist start input
  const [waitlistStartTime, setWaitlistStartTime] = useState("09:00");
  // State waitlist end input
  const [waitlistEndTime, setWaitlistEndTime] = useState("18:00");
  // State that user is on waitlist
  const [onWaitlist, setOnWaitlist] = useState(false);
  // State to show booking overview
  const [showOverview, setShowOverview] = useState(false);
  // State to show current step
  const [currentStep, setCurrentStep] = useState(1);
  // State to indicate manual step
  const [manualStepChange, setManualStepChange] = useState(false);
  // Ref of booking container
  const bookingContainerRef = useRef(null);
  // State to check if container is in viewport
  const isInView = useInView(bookingContainerRef, { once: true });
  // Use query params to allow booking from list and highlights
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  const location = useLocation();

  const { pathname } = location;

  const navigate = useNavigate();

  const serviceId = query.get("serviceId");
  // Items in the list
  const items = listItems;
  // Steps availble and icons
  const steps = [
    { step: 1, icon: "fas fa-sitemap" },
    { step: 2, icon: "fas fa-calendar" },
    { step: 3, icon: "fas fa-clock" },
    { step: 4, icon: "fas fa-user" },
    { step: 5, icon: "fas fa-list" },
    { step: 6, icon: "fas fa-check" },
  ];
  // Check query params and select the item
  useEffect(() => {
    if (serviceId) {
      const filteredItem = items.find(
        (item) => item.serviceId === serviceId && item.bookable
      );

      if (filteredItem) {
        setSelectedItems([filteredItem]);

        navigate(pathname, { replace: true });
      }
    }
  }, [serviceId, items, navigate, pathname]);
  // Check if user data is already present and validate form
  useEffect(() => {
    const firstName = localStorage.getItem("firstname") || "";
    const lastName = localStorage.getItem("lastname") || "";
    const email = localStorage.getItem("mail") || "";
    const phone = localStorage.getItem("phone") || "";

    if (firstName && lastName && email && phone) {
      setIsFormValid(true);
    }
  }, []);

  // Get a list of available timeslots
  const fetchAvailableTimeslots = async (serviceIds, date) => {
    const formattedDate = new Date(date).toISOString().split("T")[0]; // format yyyy-mm-dd
    const url = `https://www.etermin.net/api/timeslots?date=${formattedDate}&serviceid=${serviceIds.join(
      ","
    )}&rangesearch=0&capacity=1&caching=false&duration=0&cluster=false&slottype=0&fillcalendarstrategy=0&showavcap=false&appfuture=180&appdeadline=60&msdcm=0&oneoff=null&appdeadlinewm=0`;
    try {
      const response = await apiRequest(url, "GET");
      return response;
    } catch (error) {
      console.error("Error fetching timeslots:", error);
    }
  };
  // Set selected items and move to day step
  const handleSelectItems = (items) => {
    const bookableItems = items.filter((item) => item.bookable); // Filter bookable items

    if (bookableItems.length === 0) {
      alert("None of the selected items are bookable."); // Show an error message
      return; // Do not proceed to the next step
    }
    setServiceSelected(true);
    setSelectedItems(bookableItems);
    setDateConfirmed(false); // Reset date confirmation when new items are selected
    setTimeslots([]); // Clear timeslots when new items are selected
    setShowOverview(false); // Reset overview when new items are selected
    setCurrentStep(2); // Move to the next step
    scrollToTop();
  };
  // Scroll to top of container on each step
  const scrollToTop = () => {
    const element = bookingContainerRef.current;
    const navbar = document.getElementById("navbar");
    const offset = navbar ? navbar.offsetHeight : 0;

    if (element) {
      window.scrollTo({
        top: element.offsetTop - offset,
        behavior: "smooth",
      });
    }
  };
  // Calculate the progress for step navigation
  const calculateProgressWidth = () => {
    var progress;
    if (currentStep === 1) return (100 / steps.length) * 0.5;
    if (currentStep === 2) return (100 / steps.length) * 1.5;
    if (currentStep === 3) return (100 / steps.length) * 2.5;
    if (currentStep === 4) return (100 / steps.length) * 3.5;
    if (currentStep === 5) return (100 / steps.length) * 4.5;
    if (currentStep === 6) return (100 / steps.length) * 6;
    return progress;
  };
  // Return day name in german
  /*   const getDayNameInGerman = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("de-DE", { weekday: "long" });
  }; */
  // Handle the user input on date
  const handleDateChange = (e) => {
    const selectedDateValue = e.target.value;
    const selectedDay = new Date(selectedDateValue).getDay();
    setSelectedDate(`${selectedDateValue}`);
    // 0 = Sunday, 1 = Monday
    if (selectedDay === 0 || selectedDay === 1) {
      alert(
        "Sonntag und Montag sind nicht verfügbar. Bitte wähle einen anderen Tag."
      );
      return;
    }
    setDateConfirmed(false); // Reset date confirmation when the date is changed
    setShowOverview(false); // Reset overview when the date is changed
  };
  // Confirm date input
  const handleDateConfirm = async () => {
    if (selectedItems.length > 0) {
      setLoading(true);
      const serviceIds = selectedItems.map((item) => item.serviceId);
      const fetchedTimeslots = await fetchAvailableTimeslots(
        serviceIds,
        selectedDate
      );
      setTimeslots(fetchedTimeslots);
      setDateConfirmed(true); // Set date confirmation when date is confirmed
      setLoading(false);
      setCurrentStep(3); // Move to the next step
      scrollToTop();
    }
  };
  // If selected day has no result look for next day
  const searchNextDay = async () => {
    let nextDate = new Date(selectedDate);
    nextDate.setDate(nextDate.getDate() + 1);
    const formattedNextDate = nextDate.toISOString().split("T")[0];
    setSelectedDate(formattedNextDate);
    // Only proceed if at least one service is selected
    if (selectedItems.length > 0) {
      setLoading(true);
      const serviceIds = selectedItems.map((item) => item.serviceId);
      let fetchedTimeslots = await fetchAvailableTimeslots(
        serviceIds,
        nextDate
      );
      // If no timeslots on selected date are available, search for next day with free slots
      while (fetchedTimeslots.length === 0) {
        nextDate.setDate(nextDate.getDate() + 1);
        const newFormattedNextDate = nextDate.toISOString().split("T")[0];
        setSelectedDate(newFormattedNextDate);
        fetchedTimeslots = await fetchAvailableTimeslots(serviceIds, nextDate);
      }
      setTimeslots((prevTimeslots) => [...prevTimeslots, ...fetchedTimeslots]);
      setDateConfirmed(true);
      setLoading(false);
      scrollToTop();
    }
  };

  // Select available timeslot
  const handleTimeslotSelect = (timeslot) => {
    setSelectedTimeslot(timeslot); // Set selected timeslot
    setTimeslotConfirmed(false); // Reset the confirmation state
    setShowOverview(false); // Reset overview when a new timeslot is selected
  };
  // Confirm timeslot
  const confirmTimeslot = () => {
    setTimeslotConfirmed(true);
    setCurrentStep(4); // Advance to the next step
    scrollToTop();
  };
  // Confirm waitlist timeslot
  const confirmWaitlistSlot = () => {
    setTimeslotConfirmed(true);
    setCurrentStep(4);
    setOnWaitlist(true); // Mark that we are on the waitlist
    scrollToTop();
  };
  // Get user input from form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateForm({ ...userDetails, [name]: value });
  };
  // Validate the form contains the required fields
  const validateForm = (formValues) => {
    const { firstName, lastName, email, phone } = formValues;
    if (firstName && lastName && email && phone) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };
  // Show booking overview
  const showOverviewStep = () => {
    setShowOverview(true); // Change overview step to be visible
    setIsFormSubmitted(true); // Change state so form can be sent
    setCurrentStep(5); // Move to the overview step
    scrollToTop();
  };
  // Get icons per category for services
  const getCategoryIcon = (category) => {
    switch (category) {
      case "VIP":
        return <FaStar />;
      case "Haare":
        return <RiScissorsLine />;
      case "Bart":
        return <TbRazor />;
      case "Kosmetik":
        return <FaMask />;
      default:
        return null;
    }
  };
  // Create the appointment
  const createAppointment = async () => {
    const calendarid = onWaitlist ? "-1" : "178251"; // Use -1 for waitlist
    const calendarname = onWaitlist ? "Warteliste" : ""; // Add calendarname for waitlist
    const services = selectedItems.map((item) => item.serviceId).join(",");
    const { firstName, lastName, email, phone } = userDetails;

    let start, end;
    if (onWaitlist) {
      start = waitlistStartTime;
      end = waitlistEndTime;
    } else {
      start = selectedTimeslot.start;
      end = selectedTimeslot.end;
    }

    if (!onWaitlist) {
      localStorage.setItem("next_appointment_start", start);
      localStorage.setItem("next_appointment_end", end);
    }

    localStorage.setItem("firstname", firstName);
    localStorage.setItem("lastname", lastName);
    window.dispatchEvent(new Event("storage"));
    setAppointmentDeleted(false);

    const payload = new URLSearchParams({
      calendarid,
      calendarname,
      services,
      start,
      end,
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Phone: phone,
      sendemail: 1,
      checkexist: 1,
      appointmentreminderhours: -24,
      appointmentreminderhours2: -23,
    }).toString();

    const headers = { "Content-Type": "text/plain" };

    try {
      const response = await apiRequest(
        "https://www.etermin.net/api/appointment",
        "POST",
        payload,
        headers
      );

      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response, "application/xml");
      const status = xmlDoc.getElementsByTagName("status")[0]?.textContent;
      const statusMsg =
        xmlDoc.getElementsByTagName("statusmsg")[0]?.textContent;
      const appointmentId = xmlDoc.getElementsByTagName("id")[0]?.textContent;
      localStorage.setItem("appointment_id", appointmentId);
      if (status === "200") {
        console.log("Appointment created successfully", statusMsg);
        setShowOverview(false);
        setAppointmentCreated(true);
        setShowConfetti(true);
        setTimeout(() => {
          setShowConfetti(false);
        }, 5000);
        scrollToTop();
      } else {
        console.error("Error creating appointment", status, statusMsg);
      }
    } catch (error) {
      console.error("Error creating appointment", error);
      alert('Termin konnte nicht erstellt werden.')
      setCurrentStep(1); 
      setOnWaitlist(false);
      setShowWaitlistTimeInput(false);
      setTimeslotConfirmed(false);
      setIsFormSubmitted(false);
      setServiceSelected(false);
    }
  };
  // Delete appointment
  const deleteAppointment = async () => {
    const appointmentId = localStorage.getItem("appointment_id");
    localStorage.removeItem("next_appointment_start");
    localStorage.removeItem("next_appointment_end");
    window.dispatchEvent(new Event("storage"));

    const payload = new URLSearchParams({
      id: appointmentId,
    }).toString();

    const headers = { "Content-Type": "text/plain" };

    try {
      const response = await apiRequest(
        "https://www.etermin.net/api/appointment",
        "DELETE",
        payload,
        headers
      );

      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response, "application/xml");
      const status = xmlDoc.getElementsByTagName("status")[0]?.textContent;
      const statusMsg =
        xmlDoc.getElementsByTagName("statusmsg")[0]?.textContent;
      if (status === "200") {
        console.log("Appointment deleted successfully", statusMsg);
        setShowOverview(false);
        setAppointmentCreated(false);
        setAppointmentDeleted(true);
        scrollToTop();
      } else {
        console.error("Error deleting appointment", status, statusMsg);
      }
    } catch (error) {
      console.error("Error deleting appointment", error);
    }
  };
  // Format timeslot to german format
  const formatTimeslot = (start /* end */) => {
    const startDate = new Date(start);
    /* const endDate = new Date(end); */
    const dayName = startDate.toLocaleDateString("de-DE", { weekday: "long" });

    const formattedStartDate = startDate.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
    const formattedStartTime = startDate.toLocaleTimeString("de-DE", {
      hour: "2-digit",
      minute: "2-digit",
    });
    /* const formattedEndTime = endDate.toLocaleTimeString("de-DE", {
      hour: "2-digit",
      minute: "2-digit",
    }); */

    /* return `${dayName}, ${formattedStartDate} - ${formattedStartTime} - ${formattedEndTime}`; */
    return `${dayName}, ${formattedStartDate} - ${formattedStartTime}`;
  };

  const formatDate = (start) => {
    const startDate = new Date(start);
    const dayName = startDate.toLocaleDateString("de-DE", { weekday: "long" });

    const formattedStartDate = startDate.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });

    return `${dayName}, ${formattedStartDate}`;
  };

  // Progress to the next step
  const handleExitComplete = () => {
    if (manualStepChange) {
      setManualStepChange(false); // Reset the manual step change flag
      setOnWaitlist(false);
      setShowWaitlistTimeInput(false);
      setTimeslotConfirmed(false);
      setIsFormSubmitted(false);
      setServiceSelected(false);
      return; // Exit early to prevent automatic step advancement
    }
    // Trigger the next step after the exit animation completes
    if (currentStep === 1 && serviceSelected) {
      setCurrentStep(2);
    } else if (currentStep === 2 && (dateConfirmed || onWaitlist)) {
      setCurrentStep(3);
    } else if (currentStep === 3 && timeslotConfirmed) {
      setCurrentStep(4);
    } else if (currentStep === 4 && isFormSubmitted) {
      setCurrentStep(5);
    } else if (currentStep === 5 && appointmentCreated) {
      setCurrentStep(6);
    }
  };

  // Switch steps
  const handleStepClick = (step) => {
    if (step < currentStep) {
      setManualStepChange(true); // Set the manual step change flag
      if (step === 3) setTimeslotConfirmed(true); // Preserve timeslotConfirmed when moving back to step 3
      setCurrentStep(step);
      scrollToTop();
    }
  };

  // Define default animation

  const animationConfig = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 },
    transition: { duration: 0.5 },
  };

  return isOnline ? (
    <div
      className="bookings-container"
      style={{
        transform: isInView ? "none" : "translateY(100px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
      }}
      ref={bookingContainerRef}
    >
      <div className="bookings-steps">
        {steps.map(({ step, icon }) => (
          <div
            key={step}
            className={`step-indicator ${currentStep >= step ? "active" : ""}`}
            onClick={() => handleStepClick(step)}
          >
            <div className="step-circle">
              <i className={icon}></i>
            </div>
          </div>
        ))}
        <div className="step-line">
          <div
            className="progress-line"
            style={{ width: `${calculateProgressWidth()}%` }}
          ></div>
        </div>
      </div>
      <AnimatePresence onExitComplete={handleExitComplete}>
        {currentStep === 1 && (
          <motion.div {...animationConfig} key="step1">
            <List
              isBookingForm={true}
              onSelectItems={handleSelectItems}
              serviceId={serviceId}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence onExitComplete={handleExitComplete}>
        {currentStep === 2 && selectedItems.length > 0 && (
          <motion.div {...animationConfig} key="step2">
            <div className="date-picker-wrapper">
              <h3>Tag auswählen</h3>
              <input
                type="date"
                id="booking-date"
                value={selectedDate}
                onChange={handleDateChange}
              />
              <button
                onClick={handleDateConfirm}
                className="btn-confirm btn-outline"
                disabled={loading}
              >
                Datum bestätigen
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence onExitComplete={handleExitComplete}>
        {currentStep === 3 && dateConfirmed && showWaitlistTimeInput && (
          <motion.div
            {...animationConfig}
            key="waitlist-wrapper"
            className="waitlist-wrapper"
          >
            <h3>Warteliste</h3>
            <label>
              <strong>Startzeit:</strong>
            </label>
            <input
              type="time"
              min="09:00"
              max="18:00"
              value={waitlistStartTime}
              onChange={(e) => setWaitlistStartTime(e.target.value)}
              required
            />
            <label>
              <strong>Endzeit:</strong>
            </label>
            <input
              type="time"
              value={waitlistEndTime}
              onChange={(e) => setWaitlistEndTime(e.target.value)}
              required
            />
            <button
              onClick={() => {
                confirmWaitlistSlot();
              }}
              className="btn-confirm btn-outline"
              disabled={!waitlistStartTime || !waitlistEndTime}
            >
              Zeiten bestätigen
            </button>
          </motion.div>
        )}
        {currentStep === 3 && dateConfirmed && timeslots.length > 0 ? (
          <motion.div {...animationConfig} key="timeslots-wrapper">
            <div className="timeslots-wrapper">
              <h3>Zeit auswählen</h3>
              <ul className="list-items">
                {timeslots.map((slot) => (
                  <li
                    className={`list-item ${
                      selectedTimeslot?.idandtimeslot === slot.idandtimeslot
                        ? "selected"
                        : ""
                    }`}
                    key={slot.idandtimeslot}
                    onClick={() => handleTimeslotSelect(slot)}
                  >
                    {formatTimeslot(slot.start, slot.end)}
                  </li>
                ))}
              </ul>
              <button
                onClick={() => {
                  confirmTimeslot();
                }}
                className="btn-confirm btn-outline"
                disabled={!selectedTimeslot}
              >
                Zeit bestätigen
              </button>

              <button
                onClick={searchNextDay}
                className="btn-confirm btn-dark"
                disabled={loading}
              >
                Weitere Termine
              </button>
            </div>
          </motion.div>
        ) : (
          currentStep === 3 &&
          dateConfirmed &&
          !showWaitlistTimeInput && (
            <motion.div {...animationConfig} key="no-timeslots">
              <div className="timeslots-wrapper">
                <h3>Zeit auswählen</h3>
                <ul className="list-items">
                  <li className="list-item">
                    <span>
                      <i className="fas fa-exclamation-triangle"></i> Keine
                      Termine verfügbar.
                    </span>
                  </li>
                </ul>
                <button
                  onClick={searchNextDay}
                  className="btn-confirm btn-outline"
                  disabled={loading}
                >
                  Nächster verfügbarer Tag
                </button>
                <button
                  onClick={() => setShowWaitlistTimeInput(true)}
                  className="btn-confirm btn-dark"
                  disabled={loading}
                >
                  Warteliste
                </button>
              </div>
            </motion.div>
          )
        )}
      </AnimatePresence>
      <AnimatePresence onExitComplete={handleExitComplete}>
        {currentStep === 4 && (selectedTimeslot || onWaitlist) && (
          <motion.div {...animationConfig} key="data-wrapper">
            <div className="user-details-form">
              <h3>Deine Daten</h3>
              <input
                type="text"
                name="firstName"
                placeholder="Vorname"
                value={userDetails.firstName}
                onChange={handleInputChange}
                required
              />
              <input
                type="text"
                name="lastName"
                placeholder="Nachname"
                value={userDetails.lastName}
                onChange={handleInputChange}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Mail Adresse"
                value={userDetails.email}
                onChange={handleInputChange}
                required
              />
              <input
                type="tel"
                name="phone"
                placeholder="Telefonnummer"
                value={userDetails.phone}
                onChange={handleInputChange}
                required
              />
              <button
                onClick={showOverviewStep}
                className="btn-confirm btn-outline"
                disabled={!isFormValid}
              >
                {isFormValid
                  ? "Übersicht anzeigen"
                  : "Deine Daten sind unvollständig"}
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence onExitComplete={handleExitComplete}>
        {currentStep === 5 && showOverview && (
          <motion.div {...animationConfig} key="overview-wrapper-overview">
            <div className="overview-wrapper">
              <h3>Übersicht</h3>
              <p>
                <strong>Persönliche Daten:</strong>
              </p>
              <p>
                {userDetails.firstName} {userDetails.lastName}
              </p>
              <p>
                <strong>{userDetails.email}</strong>
              </p>
              <p>{userDetails.phone}</p>
              <p>
                <strong>Leistung(en):</strong>
              </p>
              <ul className="list-items">
                {selectedItems.map((item) => (
                  <li className="list-item" key={item.id}>
                    <span className="list-item-details">
                      <span className="list-item-category">
                        {getCategoryIcon(item.category)}
                      </span>
                      <span className="list-item-title">{item.name}</span>
                    </span>
                    <span className="list-item-price">{item.price}</span>
                  </li>
                ))}
              </ul>
              <p>
                <strong>Datum:</strong>
              </p>
              {selectedTimeslot && (
                <ul className="list-items">
                  <li className="list-item">
                    <span className="list-item-details">
                      <span className="list-item-title">
                        {formatTimeslot(
                          selectedTimeslot.start,
                          selectedTimeslot.end
                        )}
                      </span>
                    </span>
                  </li>
                </ul>
              )}
              {onWaitlist && (
                <ul className="list-items">
                  <li className="list-item">
                    <span className="list-item-details">
                      <span className="list-item-title">
                        Warteliste am {formatDate(selectedDate)}
                      </span>
                    </span>
                  </li>
                </ul>
              )}
              <p>
                Mit dem Abschicken des Formulars erklärst du dich mit unserer{" "}
                <a className="privacy-link" target="_blank" href="/legal">
                  Datenschutzvereinbarung
                </a>{" "}
                einverstanden.
              </p>
              <button
                onClick={createAppointment}
                className="btn-confirm btn-outline"
                style={{ marginTop: "1rem" }}
              >
                Termin erstellen
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence onExitComplete={handleExitComplete}>
        {currentStep === 6 && appointmentCreated && (
          <motion.div {...animationConfig} key="overview-wrapper-create">
            <div className="overview-wrapper">
              <h3>Termin erfolgreich erstellt</h3>
              <p>
                Du erhältst in Kürze eine Bestätigung inklusive Kalenderdatei
                per Mail an folgende Adresse:
              </p>
              <p>
                <strong>{userDetails.email}</strong>
              </p>
              <p>
                <strong>Leistung(en):</strong>
              </p>
              <ul className="list-items">
                {selectedItems.map((item) => (
                  <li className="list-item" key={item.id}>
                    <span className="list-item-details">
                      <span className="list-item-category">
                        {getCategoryIcon(item.category)}
                      </span>
                      <span className="list-item-title">{item.name}</span>
                    </span>
                    <span className="list-item-price">{item.price}</span>
                  </li>
                ))}
              </ul>
              <p>
                <strong>Datum:</strong>
              </p>
              {selectedTimeslot ? (
                <ul className="list-items">
                  <li className="list-item">
                    <span className="list-item-details">
                      <span className="list-item-title">
                        {formatTimeslot(
                          selectedTimeslot.start,
                          selectedTimeslot.end
                        )}
                      </span>
                    </span>
                  </li>
                </ul>
              ) : (
                <ul className="list-items">
                  <li className="list-item">
                    <span className="list-item-details">
                      <span className="list-item-title">
                        Warteliste am {formatDate(selectedDate)}
                      </span>
                    </span>
                  </li>
                </ul>
              )}
              <NavLink className="btn-outline back-to-home" to={"/"}>
                Zurück zur Homepage
              </NavLink>
              <p className="delete-appointment">
                <strong>Du hast einen Fehler gemacht?</strong>
              </p>
              <button className="btn-dark" onClick={deleteAppointment}>
                Termin stornieren
              </button>
            </div>
            {showConfetti && <Confetti />}
          </motion.div>
        )}

        {currentStep === 6 && appointmentDeleted && (
          <motion.div {...animationConfig} key="overview-wrapper-delete">
            <div className="overview-wrapper">
              <h3>Termin erfolgreich storniert</h3>
              <p>
                Du erhältst in Kürze eine Bestätigung per Mail an folgende
                Adresse:
              </p>
              <p>
                <strong>{userDetails.email}</strong>
              </p>
              <NavLink className="btn-outline back-to-home" to={"/"}>
                Zurück zur Homepage
              </NavLink>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  ) : (
    <div className="bookings-container">
      <motion.div {...animationConfig} key="overview-wrapper-delete">
        <div className="overview-wrapper">
          <h3>Du bist gerade offline!</h3>
          <p>
            Solange du nicht mit dem Internet verbunden bist, kannst du keinen
            Termin buchen.
          </p>
          <NavLink className="btn-outline back-to-home" to={"/"}>
            Zurück zur Homepage
          </NavLink>
        </div>
      </motion.div>
    </div>
  );
};

export default Booking;
