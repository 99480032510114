export const ContactpageUserform = [
        {
          firstname: 'true',
          lastname: 'true',
          email: 'true',
          phone: 'true',
          message: 'true',
          sendMail: 'true',
          sendText: 'Anfrage senden',
          infoText: 'Falls beim Ausfüllen des Formulars Probleme auftreten, schreib uns eine E-Mail an info@xs-barbershop.com.',
          privacyText: 'Mit dem Abschicken des Formulars erklärst du dich mit unserer <a href="/legal">Datenschutzvereinbarung</a> einverstanden.',
          id: 1,
          idText: 2,
          section: 1,
        },

  ]

  export const ContactpageHeadline = [
    {
      className: 'headline headline-center',
      containerClassName: 'headline-container',
      headline: 'Schreib uns eine Nachricht',
      id: 1,
      section: 1
    },
  ]

export const ContactpageBox = [
    
  { 
    title: 'Addresse', 
    description: 'XS-Barbershop GmbH', 
    secondDescription: 'Fabrikstraße 32', 
    thirdDescription: '63225 Langen', 
    className: 'box half box-dark', 
    headlineClassname: 'box-headline headline-bright', 
    textClassname: 'box-text text-bright text-no-margin',  
    buttonText: '', 
    buttonClassname: 'btn-dark', 
    imgClassName: 'box-img box-img-rounded', 
    boxIcon: 'fas fa-map',
    id: 1, 
    section: 1 
  },
  { 
    title: 'Kontakt', 
    description: '', 
    secondDescription: '', 
    linkName: '06103 4877822', 
    linkTarget:'tel:061034877822', 
    linkNameIcon:'fa fa-phone', 
    secondLinkName: 'info@xs-barbershop.com', 
    secondLinkTarget:'mailto:info@xs-barbershop.com', 
    secondLinkNameIcon:'fa fa-envelope', 
    thirdLinkName: 'Maps', 
    thirdLinkTarget:'https://maps.app.goo.gl/oge8Ve4fdU3xjiyz6', 
    thirdLinkNameIcon:'fas fa-compass', 
    className: 'box half box-dark', 
    headlineClassname: 'box-headline headline-bright', 
    textClassname: 'box-text text-bright text-no-margin', 
    buttonText: '', 
    buttonClassname: 'btn-dark', 
    imgClassName: 'box-img box-img-rounded',
    boxIcon: 'fas fa-address-card', 
    id: 7, 
    section: 1 
  },
  ]

export const ContactpageHero = [
    {
      title: "Kontakt",
      description:
        "Kontaktiere uns! Hier findest du unsere E-Mail-Adresse, Telefonnummer und ein praktisches Kontaktformular. Wir freuen uns darauf, von dir zu hören und stehen für Anfragen bereit.",
      className: "hero",
      containerClassName: "hero-container",
      backgroundImage: require('../images/hero-bg.jpg'),
      buttonClass: "btn-outline",
      buttonText: "Rufe uns an",
      buttonLink: "tel:061034877822",
      imgClassName: "",
      icon: "fas fa-phone",
      id: 1,
    },
  ]

export const ContactpageCta = [
  {
    headline: 'Noch unschlüssig? Schau mal in die Galerie!',
    description: 'Sieh dir unsere Galerie mit Vorher-Nachher-Bildern und zufriedenen Kunden an. Klicke hier und lass dich überzeugen!',
    className: 'cta full',
    containerClassName: 'cta-container default-shadow',
    buttonClass: 'btn-outline',
    buttonText: 'Zur Galerie',
    buttonLink: '/projects',
    imgClassName: '',
    id: 1,
    section: 1,
  },
  ]
export const ContactpageMeta = ''

